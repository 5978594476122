import { ActionContext, ActionTree } from "vuex"
import { RootState } from "../../index"
import { MutationType, Mutations } from "./mutations"
import { State } from "./state"

export enum ActionTypes {
    SET_CREATE_ASSESSMENT_PERMISSION = "SET_CREATE_ASSESSMENT_PERMISSION",
    SET_EDIT_ASSESSMENT_PERMISSION = "SET_EDIT_ASSESSMENT_PERMISSION",
    SET_DELETE_ASSESSMENT_PERMISSION = "SET_DELETE_ASSESSMENT_PERMISSION",
    SET_ARCHIVE_ASSESSMENT_PERMISSION = "SET_ARCHIVE_ASSESSMENT_PERMISSION",
    SET_COPY_ASSESSMENT_PERMISSION = "SET_COPY_ASSESSMENT_PERMISSION",
    SET_INVITE_CANDIDATE_PERMISSION = "SET_INVITE_CANDIDATE_PERMISSION",
    SET_DELETE_CANDIDATE_PERMISSION = "SET_DELETE_CANDIDATE_PERMISSION",
    SET_USER_PERMISSION = "SET_USER_PERMISSION",
    SET_ADD_USER_PERMISSION = "SET_ADD_USER_PERMISSION",
    SET_CHANGE_USER_ROLE_PERMISSION = "SET_CHANGE_USER_ROLE_PERMISSION",
    SET_CHANGE_USER_STATUS_PERMISSION = "SET_CHANGE_USER_STATUS_PERMISSION",
    SET_ROLES_PERMISSION = "SET_ROLES_PERMISSION",
    SET_DEVELOPER_PERMISSION = "SET_DEVELOPER_PERMISSION",
    SET_WHITE_LABEL_PERMISSION = "SET_WHITE_LABEL_PERMISSION",
    SET_SUBSCRIPTION_PERMISSION = "SET_SUBSCRIPTION_PERMISSION",
}

type ActionAugments = Omit<ActionContext<State, RootState>, "commit"> & {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1]
    ): ReturnType<Mutations[K]>
}

export type Actions = {
    [ActionTypes.SET_CREATE_ASSESSMENT_PERMISSION](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_EDIT_ASSESSMENT_PERMISSION](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_DELETE_ASSESSMENT_PERMISSION](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_ARCHIVE_ASSESSMENT_PERMISSION](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_COPY_ASSESSMENT_PERMISSION](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_INVITE_CANDIDATE_PERMISSION](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_DELETE_CANDIDATE_PERMISSION](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_USER_PERMISSION](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_ADD_USER_PERMISSION](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_CHANGE_USER_ROLE_PERMISSION](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_CHANGE_USER_STATUS_PERMISSION](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_ROLES_PERMISSION](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_DEVELOPER_PERMISSION](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_WHITE_LABEL_PERMISSION](
        { commit }: ActionAugments,
        value: boolean
    ): void
    [ActionTypes.SET_SUBSCRIPTION_PERMISSION](
        { commit }: ActionAugments,
        value: boolean
    ): void
}

export const actions: ActionTree<State, RootState> & Actions = {
    [ActionTypes.SET_CREATE_ASSESSMENT_PERMISSION]({ commit }, value) {
        commit(MutationType.SET_CREATE_ASSESSMENT_PERMISSION, value)
    },
    [ActionTypes.SET_EDIT_ASSESSMENT_PERMISSION]({ commit }, value) {
        commit(MutationType.SET_EDIT_ASSESSMENT_PERMISSION, value)
    },
    [ActionTypes.SET_DELETE_ASSESSMENT_PERMISSION]({ commit }, value) {
        commit(MutationType.SET_DELETE_ASSESSMENT_PERMISSION, value)
    },
    [ActionTypes.SET_ARCHIVE_ASSESSMENT_PERMISSION]({ commit }, value) {
        commit(MutationType.SET_ARCHIVE_ASSESSMENT_PERMISSION, value)
    },
    [ActionTypes.SET_COPY_ASSESSMENT_PERMISSION]({ commit }, value) {
        commit(MutationType.SET_COPY_ASSESSMENT_PERMISSION, value)
    },
    [ActionTypes.SET_INVITE_CANDIDATE_PERMISSION]({ commit }, value) {
        commit(MutationType.SET_INVITE_CANDIDATE_PERMISSION, value)
    },
    [ActionTypes.SET_DELETE_CANDIDATE_PERMISSION]({ commit }, value) {
        commit(MutationType.SET_DELETE_CANDIDATE_PERMISSION, value)
    },
    [ActionTypes.SET_USER_PERMISSION]({ commit }, value) {
        commit(MutationType.SET_USER_PERMISSION, value)
    },
    [ActionTypes.SET_ADD_USER_PERMISSION]({ commit }, value) {
        commit(MutationType.SET_ADD_USER_PERMISSION, value)
    },
    [ActionTypes.SET_CHANGE_USER_ROLE_PERMISSION]({ commit }, value) {
        commit(MutationType.SET_CHANGE_USER_ROLE_PERMISSION, value)
    },
    [ActionTypes.SET_CHANGE_USER_STATUS_PERMISSION]({ commit }, value) {
        commit(MutationType.SET_CHANGE_USER_STATUS_PERMISSION, value)
    },
    [ActionTypes.SET_ROLES_PERMISSION]({ commit }, value) {
        commit(MutationType.SET_ROLES_PERMISSION, value)
    },
    [ActionTypes.SET_DEVELOPER_PERMISSION]({ commit }, value) {
        commit(MutationType.SET_DEVELOPER_PERMISSION, value)
    },
    [ActionTypes.SET_WHITE_LABEL_PERMISSION]({ commit }, value) {
        commit(MutationType.SET_WHITE_LABEL_PERMISSION, value)
    },
    [ActionTypes.SET_SUBSCRIPTION_PERMISSION]({ commit }, value) {
        commit(MutationType.SET_SUBSCRIPTION_PERMISSION, value)
    },
}
