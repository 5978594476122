<template>
    <div
        class="d-flex justify-content-center align-items-center no-record-txt"
        v-if="loading"
    >
        <span class="loader-circle-primary"></span>
    </div>
    <router-view :style="brandcolor" v-if="!loading" />
    <!-- G2 future use code-->
    <!--    <a
            v-if="
                showG2Review &&
                !isReseller &&
                $route.name !== 'Login' &&
                $route.name !== 'Register'
            "
            href="https://www.g2.com/products/testlify/take_survey?utm_source=review-widget"
            title="Write a review of Testlify on G2"
            target="_blank"
            class="g2-review sticky-box-left-side"
        >
            <div class="d-flex center-content">
                <span class="review-text">{{
                    $t("button_label.review_us_on")
                }}</span>
                <img
                    class="full-width"
                    alt="Review Testlify on G2"
                    src="/src/assets/media/logos/g2-logo.png"
                />
            </div>
        </a>        -->
    <SubscriptionInactiveModal
        v-if="showSubscriptionInactiveModal"
    ></SubscriptionInactiveModal>
</template>

<script lang="ts">
import { store, useStore } from "@/store"
import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    ref,
    watch,
    watchEffect,
} from "vue"
import { toast, updateGlobalOptions } from "vue3-toastify"
import "vue3-toastify/dist/index.css"
// import "vue-multiselect/dist/vue-multiselect.css"
import i18n from "@/plugins/i18n"
import useApiAuth from "@/services/apiAuth"
import { ActionTypes as GlobalActions } from "@/store/modules/global/actions"
import { ActionTypes as WorkspaceActions } from "@/store/modules/workspace/actions"
import moment from "moment/moment"
/*import WidgetHelpDesk from "@/view/pages/general/auth-forms/widget-helpdesk/WidgetHelpDesk.vue";*/
import axios from "axios"
// @ts-ignore
import DefaultFavicon from "@/assets/favicon-red.ico"
import JoinFavicon from "@/assets/join.ico"
import ResellerHelper from "@/helper-functions/resellerHelperFunctions"
import useTranslation from "@/locales/useTranslation"
import useApiAssessment from "@/services/apiAssessment"
import SubscriptionInactiveModal from "@/view/modal/SubscriptionInactiveModal.vue"
import { jam } from "@jam.dev/sdk"
import { useI18n } from "vue-i18n"
import "vue-multiselect/dist/vue-multiselect.css"
import { useRoute } from "vue-router"
import { PRIVACY_POLICY_URL, TERMS_CONDITION_URL } from "./core/constants"
import helperFunction from "./helper-functions/helperFunctions"
import useApiRoles from "/src/services/apiRoles"
import useOAuth from "./services/oauth"
export default defineComponent({
    computed: {
        brandcolor() {
            return {
                "--brandcolor": store.getters.getWhiteLabelBrandColor,
                "--dark-brandcolor":
                    store.getters.getWhiteLabelDarkerBrandColor,
                "--plyr-color-main": store.getters.getWhiteLabelBrandColor,
                "--plyr-video-background": "transparent",
                "--brand-text-color": store.getters.getWhiteLabelBrandTextColor,
            }
        },
    },
    components: { SubscriptionInactiveModal },
    setup() {
        const {
            checkResellers,
            setResellerName,
            setResellerDomain,
            setResellerStatus,
            setResellerManageWorkspaceStatus,
            setLinkToResellerSubscriptionStatus,
        } = ResellerHelper()
        const store = useStore()
        const {
            setFavicon,
            showImagePreview,
            isTestlifyDomain,
            saveAdTrackingParams,
        } = helperFunction()
        const isBot = /bot|google|baidu|bing|msn|teoma|slurp|yandex/i.test(
            navigator.userAgent
        )
        const loading = ref(true)
        const route = useRoute()
        const { API_PREFIX } = useOAuth()
        // sso redirect rule for faster redirect
        if (
            window.location.pathname.startsWith("/sso/") &&
            window.location.pathname.split("/")[2] !== undefined
        ) {
            location.href = `${API_PREFIX}/sso/saml/login?teamSlug=${
                window.location.pathname.split("/")[2]
            }`
        }
        const { apiGetLanguages, apiGetLanguageJson } = useTranslation()
        const isWhiteLabel = computed(() => store.getters.getWhiteLabelStatus)
        const { apiGetNotificationList } = useApiAssessment()
        const { apiUserProfileSummary } = useApiAuth()
        const t = useI18n()
        const getAuthUser = computed(() => store.getters.getAuthUser)
        const langCode = computed(() => store.getters.getDefaultLanguage)
        const workspaceName = computed(() => store.getters.getWorkspaceName)
        const workspaceUrl = computed(() => store.getters.getWorkspaceUrl)
        const { whiteLabeledPlan, basicPlan } = helperFunction()
        const subscriptionName = computed(() => store.getters.subscriptionName)
        const subscriptionStatus = computed(
            () => store.getters.getWorkspaceStatus
        )
        const sessionKey = computed(() => store.getters.getSessionKey)
        const subscriptionExpireDate: any = computed(
            () => store.getters.getSubscriptionExpireDate
        )
        const isTrial: any = computed(() => store.getters.isTrial)
        // const hasBeenCalled = ref(false)
        const todayDate = moment(new Date().toUTCString())
        const expiryDate = moment(
            new Date(subscriptionExpireDate.value).toUTCString()
        )
        const preciseDay = ref(expiryDate.diff(todayDate, "days", true))
        const remainingDays = ref(Number(preciseDay.value))
        const daysLeftForTrial: any = computed(
            () => store.getters.getDaysLeftForTrial
        )
        const accessRegistrationData = computed(
            () => store.getters.getAccessRegistration
        )
        const accessLoginData = computed(() => store.getters.getAccessLogin)

        const editorExtensionId = "ehnogkgbkmkefbmebflmopdcecgbpblg"
        const selectedLanguageJson: any = computed(
            () => store.getters.getLanguageData
        )
        const isReseller = computed(() => store.getters.getResellerStatus)
        const isDefaultReseller = computed(
            () => store.getters.getDefaultReseller
        )
        const applyBrandColors = (brandcolor) => {
            const root = document.documentElement
            Object.entries(brandcolor).forEach(([key, value]) => {
                root.style.setProperty(key, value as string)
            })
        }

        const showG2Review = ref(false)
        //G2 future code
        // const setG2Script = () => {
        //     ;(function (a, b, c, d) {
        //         window
        //             .fetch(
        //                 "https://www.g2.com/products/testlify/rating_schema.json"
        //             )
        //             .then((e) => e.json())
        //             .then((f) => {
        //                 c = a.createElement(b)
        //                 c.type = "application/ld+json"
        //                 c.text = JSON.stringify(f)
        //                 d = a.getElementsByTagName(b)[0]
        //                 d.parentNode.insertBefore(c, d)
        //             })
        //     })(document, "script")
        // }

        const getCountryCode = async () => {
            try {
                //to check on localhost replace '/api/ip-lookup.js' with 'https://testlify-six.vercel.app/api/ip-lookup'
                let response = await fetch(`/api/ip-lookup.js`)
                let data = await response.json()
                if (data.dialCode) {
                    store.dispatch(
                        GlobalActions.SET_CURRENTUSER_DIALCODE,
                        data.dialCode
                    )
                }
                if (data.timezone) {
                    store.dispatch(
                        GlobalActions.SET_CURRENTUSER_TIMEZONE,
                        data.timezone
                    )
                }
            } catch (error) {
                console.error(error)
            }
        }
        const executeAfterTenMinutes = (callback) =>
            setTimeout(callback, 10 * 60 * 1000)
        onBeforeMount(async () => {
            getCountryCode()
            const randomQueryString = () =>
                `?${Math.random().toString(36).substr(2, 10)}`

            if (window.location.href.includes("join")) {
                setFavicon(JoinFavicon + randomQueryString())
            } else if (window.location.origin.includes("testlify")) {
                setFavicon(DefaultFavicon + randomQueryString())
            } else {
                setFavicon("")
            }
        })

        onMounted(() => {
            saveAdTrackingParams()
            if (
                (window.location.href.includes("app.testlify.com") ||
                    window.location.href.includes("vercel.app") ||
                    window.location.href.includes("localhost")) &&
                window.location.pathname !== "/assessment/candidate"
            ) {
                window.Beacon("init", "484d5227-602b-4df5-a54f-6cc7e49020d7")
            }
        })

        watchEffect(() => {
            if (langCode.value == "ar") {
                document.dir = "rtl"
            } else {
                document.dir = "ltr"
            }
        })
        //G2 future code
        // const g2Modal = () => {
        //     const daysLeft = Number(remainingDays.value.toFixed(0))
        //     if (isTrial.value && daysLeft <= 2) {
        //         setG2Script()
        //         showG2Review.value = true
        //     } else if (!isTrial.value) {
        //         executeAfterTenMinutes(() => {
        //             setG2Script()
        //             showG2Review.value = true
        //         })
        //     }
        // }
        // watch(isTrial, (newValue, oldValue) => {
        //     g2Modal()
        // })
        const getNotificationList: any = async () => {
            const response: any = await apiGetNotificationList("", 100, 0)

            if (response === -1) {
                return
            }
            const totalUnReadNotifications = response.data.notifications.filter(
                (item) => {
                    return !item.isRead
                }
            ).length
            store.dispatch(
                GlobalActions.SET_IS_UNREAD_NOTIFICATIONS_COUNT,
                totalUnReadNotifications
            )
        }
        const getPlanName = (plan: any) => {
            if (plan === null) {
                return ""
            }
            switch (true) {
                case plan.includes("trial"):
                    return "Trial plan"
                case plan.includes("startup"):
                    return i18n.global.t("constants_text.starter_plan")
                case plan.includes("grow", "basic"):
                    return i18n.global.t("constants_text.basic_plan")
                case plan.includes("Lifetime-Deal-10000"):
                    return i18n.global.t("constants_text.premium_lifetime")
                case plan.includes("Lifetime-Deal-3000"):
                    return i18n.global.t("constants_text.business_lifetime")
                case plan.includes("Lifetime-Deal-300"):
                    return i18n.global.t("constants_text.basic_lifetime")
                case plan.includes("Lifetime-Deal-100"):
                    return i18n.global.t("constants_text.starter_lifetime")
                case plan.includes("white-labeled"):
                    return i18n.global.t("constants_text.premium_plan")
                case plan.includes("pay-as-you-go"):
                    return i18n.global.t("choose_plan.pay_per_use")
                default:
                    return i18n.global.t("constants_text.business_plan")
            }
        }
        const { apiGetUserPermission } = useApiRoles()
        const integrationAddOn = computed(() => store.getters.integrationAddOn)
        const whiteLabelAddOn = computed(() => store.getters.whiteLabelAddOn)
        const checkAddOn = computed(() => {
            if (whiteLabelAddOn.value && integrationAddOn.value) {
                return `+ ${i18n.global.t(
                    "profile_action_menu.white_label"
                )} + ${i18n.global.t("profile_action_menu.integration")}`
            } else if (whiteLabelAddOn.value) {
                return `+ ${i18n.global.t("profile_action_menu.white_label")}`
            } else if (integrationAddOn.value) {
                return `+ ${i18n.global.t("profile_action_menu.integration")}`
            } else {
                return ""
            }
        })
        const setIntercomAndHelpscout = () => {
            let initialize = false
            if (
                typeof window.Intercom === "function" &&
                getAuthUser.value.sessionAccessKey
            ) {
                const email = getAuthUser.value.email
                const emailHash = getAuthUser.value.emailHash
                const name =
                    getAuthUser.value.firstName !== undefined
                        ? (
                              getAuthUser.value.firstName +
                              " " +
                              getAuthUser.value.lastName
                          ).trim()
                        : null
                const planName = getPlanName(subscriptionName.value)
                const addOn = checkAddOn.value
                const planWithAddOn = `${planName} ${addOn}`
                const isWorkspaceActive = computed(() => {
                    return daysLeftForTrial.value <= 6 ||
                        remainingDays.value <= 0
                        ? "Inactive"
                        : subscriptionStatus.value == "active"
                        ? "Active"
                        : subscriptionStatus.value
                })
                if (workspaceName.value && email && emailHash) {
                    // window.Intercom("boot", {
                    //     api_base: "https://api-iam.intercom.io",
                    //     app_id: "vtup7zgy",
                    //     name: name || email,
                    //     email: email,
                    //     "subscription-status": isWorkspaceActive.value,
                    //     "plan-name": planWithAddOn,
                    //     "workspace-name": workspaceName.value || "",
                    //     "workspace-url": workspaceUrl.value || "",
                    //     user_hash: emailHash,
                    // })
                    initialize = true
                    window.Beacon("identify", {
                        name: name || email,
                        email: email,
                        custom_attributes_subscription_status:
                            isWorkspaceActive.value,
                        custom_attributes_plan_name: planWithAddOn,
                        custom_attributes_workspace_name:
                            workspaceName.value || "",
                        custom_attributes_workspace_url:
                            workspaceUrl.value || "",
                    })
                }
            }
            if (!initialize) {
                window.Intercom("boot", {
                    api_base: "https://api-iam.intercom.io",
                    app_id: "vtup7zgy",
                })
            }
        }
        // watch(
        //     () => route.name,
        //     () => {
        //         if (route.name === "AssessmentMain" && !hasBeenCalled.value) {
        //             getNotificationList()
        //             hasBeenCalled.value = true
        //         }
        //     }
        // )
        const bc = new BroadcastChannel("testlify")
        bc.onmessage = (event) => {
            if (event.data == "reload") {
                if (route.name !== "InviteeRegister") {
                    setTimeout(() => {
                        window.location.reload()
                    }, 2500)
                }
            }
        }

        const calculatePercentage = (done: number, total: number) => {
            if (total === 0) {
                return 0 // Avoid division by zero
            }
            const percentage = (done / total) * 100
            return Math.round(percentage)
        }
        onMounted(async () => {
            sessionStorage.removeItem("isSubscriptionExpired")

            // if (sessionKey.value && route.name !== "AssessmentMain") {
            //     hasBeenCalled.value = true
            //     getNotificationList()
            // }
            //G2 will use in future code
            //g2Modal()
            //multilingual code block start
            await apiGetLanguages("EMPLOYER")

            if (getAuthUser.value.defaultLanguage) {
                let defLang = getAuthUser.value.defaultLanguage
                const selectedLang = langCode.value ? langCode.value : defLang
                await apiGetLanguageJson(selectedLang)
                t.locale.value = defLang
                t.setLocaleMessage(defLang, selectedLanguageJson.value)
            }

            setTimeout(() => {
                loading.value = false
            }, 1000)
            //multilingual code block end
            let checkWhiteLabel = sessionStorage.getItem("showWhiteLabel")
            if (!checkWhiteLabel || checkWhiteLabel !== "0") {
                sessionStorage.setItem("showWhiteLabel", "1")
            }
            addTestingHeaderToAxiosGlobally()
            sendTokenToChrome()
            if (
                !isBot &&
                !window.location.href.includes("localhost") &&
                !window.location.href.includes("vercel")
            ) {
                window.tracker.start()
                const emailId =
                    accessRegistrationData.value?.emailId ||
                    getAuthUser.value?.email ||
                    accessLoginData.value?.emailId ||
                    ""
                if (emailId) {
                    window.tracker.setUserID(emailId)
                }
            }

            if (isTestlifyDomain) {
                store.dispatch(
                    WorkspaceActions.SET_TERMS_URL,
                    TERMS_CONDITION_URL
                )
                store.dispatch(
                    WorkspaceActions.SET_PRIVACY_URL,
                    PRIVACY_POLICY_URL
                )
            }

            if (sessionKey.value && route.query.state !== "succeeded") {
                await apiGetUserPermission()
                const profileResponse = await apiUserProfileSummary()
                const profileData = profileResponse?.userWorkspaceProfile
                const response = profileResponse?.organization

                if (profileData === -1 || response === -1) {
                    return
                }
                store.dispatch(
                    GlobalActions.SET_IS_TESTLIFY_SUPPORT_LOGIN,
                    response.isTestlifySupport
                )
                store.dispatch(
                    GlobalActions.SET_SHOW_PLANS_TAB,
                    response.planSetting
                )
                store.dispatch(
                    GlobalActions.SET_IS_ADACOMPLIANCE,
                    profileData.accessibilitySettings
                )
                store.dispatch(
                    GlobalActions.SET_SAML_SSO_AUTHENTICATION,
                    profileData.ssosamlEnabled
                )
                store.dispatch(
                    GlobalActions.SET_IS_DENYCREATEWORKSPACE,
                    response.denyWorkspaceCreation
                )
                store.dispatch(
                    GlobalActions.SET_IS_VALID_CARD,
                    response?.isValidCard
                )
                store.dispatch(
                    GlobalActions.SET_IS_PAID_USER,
                    response.isPaidUser
                )
                store.dispatch(
                    GlobalActions.SET_RESELLER_VIEW_USAGE_STATUS,
                    response.resellerViewUsage
                )
                store.dispatch(
                    GlobalActions.SET_EMAIL_REMINDER_FREQUENCY,
                    response.reminderConfig
                )
                store.dispatch(
                    WorkspaceActions.SET_IS_APPSUMO_COUPON_APPLIED,
                    response.isAppSumoCoupon
                )
                setResellerManageWorkspaceStatus(response.manageWorkspace)
                setLinkToResellerSubscriptionStatus(
                    response.linkToResellerSubscription
                )
                checkResellers(response.resellerType)
                setResellerStatus(response.isResellerActive)
                setResellerName(response.resellerName)
                setResellerDomain(response.candidateUrl)
                store.dispatch(WorkspaceActions.SET_WORKSPACE_ID, response.id)
                store.dispatch(
                    WorkspaceActions.SET_TERMS_URL,
                    response.termsUrl
                )
                store.dispatch(
                    WorkspaceActions.SET_PRIVACY_URL,
                    response.privacyPolicyUrl
                )
                store.dispatch(
                    WorkspaceActions.SET_SUPPORT_EMAIL,
                    response.supportEmail
                )
                store.dispatch(
                    WorkspaceActions.SET_CANDIDATE_NOTIFICATION,
                    response.candidateNotificationAllowed
                )
                store.dispatch(
                    WorkspaceActions.SET_ADMIN_NOTIFICATION,
                    response.administratorNotificationAllowed
                )
                store.dispatch(
                    WorkspaceActions.SET_CUSTOM_TEMPLATE_ACTIVE,
                    response.isCustomTemplateActive
                )
                store.dispatch(
                    WorkspaceActions.SET_EMAIL_TEMPLATE_NOTIFICATION,
                    response.isEmailTemplateActive
                )
                store.dispatch(
                    WorkspaceActions.SET_WHITELABEL,
                    response.isWhiteLabel
                )
                store.dispatch(
                    WorkspaceActions.SET_IS_FULL_DISCOUNT,
                    response.isFullDiscount
                )
                store.dispatch(
                    WorkspaceActions.SET_IS_PAY_PER_USAGE_PLAN,
                    response.isPayPerUsagePlan
                )
                store.dispatch(
                    WorkspaceActions.SET_IS_LEGACY_PLAN,
                    response.isLegacyPlan
                )
                store.dispatch(WorkspaceActions.SET_APPEARANCE, {
                    brandColor: response.brandColor,
                    logo: response.logo
                        ? response.logo +
                          "?" +
                          Math.random().toString(36).substr(2, 10)
                        : null,
                    favicon: response.favicon
                        ? response.favicon +
                          "?" +
                          Math.random().toString(36).substr(2, 10)
                        : null,
                    customImage: response.customImage
                        ? response.customImage +
                          "?" +
                          Math.random().toString(36).substr(2, 10)
                        : null,
                    brandTextColor: response?.brandTextColor || "#FFFFFF",
                })
                store.dispatch(
                    WorkspaceActions.SET_SUBSCRIPTION_EXPIRE_DATE,
                    response.subscriptionExpireDate
                )
                store.dispatch(
                    WorkspaceActions.SET_WORKSPACE_STATUS,
                    response.status || ""
                )
                store.dispatch(
                    WorkspaceActions.SET_IS_BILLING_ALLOWED,
                    response.isBillingAllowed
                )
                store.dispatch(WorkspaceActions.SET_IS_TRIAL, response.isTrial)
                store.dispatch(
                    WorkspaceActions.SET_DAYS_LEFT_TRIAL,
                    response.daysLeftForTrial
                )
                store.dispatch(
                    WorkspaceActions.SET_TRIAL_EXTENDED,
                    response.trialExtended
                )
                store.dispatch(
                    WorkspaceActions.SET_INTEGRATION_ADD_ON,
                    response.integrationAddOn
                )
                store.dispatch(
                    WorkspaceActions.SET_WHITE_LABEL_ADD_ON,
                    response.whiteLabelAddOn
                )
                store.dispatch(
                    WorkspaceActions.SET_IS_SUBSCRIPTION_NAME,
                    response.subscriptionName
                )

                store.dispatch(
                    GlobalActions.SET_IS_UNREAD_NOTIFICATIONS_COUNT,
                    response.unreadNotificationCount
                )

                store.dispatch(
                    WorkspaceActions.SET_WORKSPACE_WHITE_LABEL,
                    response.isResellerWhiteLabel
                )
                store.dispatch(
                    WorkspaceActions.SET_WORKSPACE_ADVANCE_PROCTORING,
                    response.resellerAdvancedProctoring
                )
                store.dispatch(
                    WorkspaceActions.SET_WORKSPACE_TRIAL_PERIOD,
                    response.isResellerTrial
                )
                store.dispatch(
                    WorkspaceActions.SET_IS_2FA_ENABLED,
                    response.totpEnabled
                )
                store.dispatch(
                    WorkspaceActions.SET_IS_2FA_VERIFIED,
                    response.totpVerified
                )
                store.dispatch(
                    WorkspaceActions.SET_2FA_ACTIVATION_DATE,
                    response.totpEnabledAt
                )
                store.dispatch(
                    WorkspaceActions.SET_IS_ENFORCED_2FA,
                    response.enforce2FA
                )
            }
            await getPlanName(subscriptionName.value)
            if (
                (window.location.href.includes("app.testlify.com") ||
                    window.location.href.includes("vercel.app") ||
                    window.location.href.includes("localhost")) &&
                window.location.pathname !== "/assessment/candidate"
            ) {
                await setIntercomAndHelpscout()
            } else {
                window.Intercom("update", { hide_default_launcher: true })
            }
            showImagePreview()
            applyBrandColors({
                "--brandcolor": store.getters.getWhiteLabelBrandColor,
                "--dark-brandcolor":
                    store.getters.getWhiteLabelDarkerBrandColor,
                "--plyr-color-main": store.getters.getWhiteLabelBrandColor,
                "--plyr-video-background": "transparent",
                "--brand-text-color": store.getters.getWhiteLabelBrandTextColor,
            })
        })

        watch(
            [workspaceName, workspaceUrl, getAuthUser, subscriptionName.value],
            () => {
                if (
                    (window.location.href.includes("app.testlify.com") ||
                        window.location.href.includes("vercel.app") ||
                        window.location.href.includes("localhost")) &&
                    window.location.pathname !== "/assessment/candidate"
                ) {
                    setIntercomAndHelpscout()
                } else {
                    window.Intercom("update", { hide_default_launcher: true })
                }
            }
        )

        watch([accessRegistrationData, getAuthUser, accessLoginData], () => {
            if (
                !isBot &&
                !window.location.href.includes("localhost") &&
                !window.location.href.includes("vercel")
            ) {
                const emailId =
                    accessRegistrationData.value?.emailId ||
                    getAuthUser.value?.email ||
                    accessLoginData.value?.emailId ||
                    ""
                if (emailId) {
                    window.tracker.setUserID(emailId)
                }
            }
        })
        // set testing header to all endpoints conditionally
        const addTestingHeaderToAxiosGlobally = () => {
            let productionTesting = localStorage.getItem("production-testing")
            if (window.location.href.includes("production-testing=true")) {
                localStorage.setItem("production-testing", "true")
                productionTesting = "true"
            }
            axios.defaults.headers.common = {
                "production-testing":
                    productionTesting && productionTesting === "true"
                        ? "true"
                        : "false",
            }
        }
        updateGlobalOptions({
            limit: 1,
        })

        const sendTokenToChrome = () => {
            if (sessionKey.value) {
                if (
                    typeof window.chrome === "object" &&
                    typeof window.chrome.runtime === "object"
                ) {
                    window.chrome.runtime.sendMessage(
                        editorExtensionId,
                        { accessToken: sessionKey.value },
                        function (response: any) {
                            let lastError = window.chrome.runtime.lastError
                            if (lastError) return
                            console.log("Chrome extension response:", response)
                            // response should be success
                            if (response == "204") {
                                toast(
                                    "Testlify chrome plugin has been activated!",
                                    {
                                        type: "success",
                                        autoClose: 4000,
                                        position: "bottom-left",
                                    }
                                )
                            }
                        }
                    )
                }
            }
        }

        jam.metadata(() => {
            const localStorageData =
                window.localStorage.getItem("testlify_storage") ?? ""
            return {
                localStorage: localStorageData,
            }
        })

        const showSubscriptionInactiveModal = computed(() => {
            return store.getters.getSubscriptionInactiveModal
        })

        return {
            //showG2Review,
            //isReseller,
            loading,
            isDefaultReseller,
            showSubscriptionInactiveModal,
        }
    },
})
</script>

<style lang="scss">
@import "#/bootstrap-icons/font/bootstrap-icons.css";
@import "#/animate.css";
@import "#/@fortawesome/fontawesome-free/css/all.min.css";
@import "#/@vueform/multiselect/themes/default.css";
@import "#/prism-themes/themes/prism-shades-of-purple.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

// Main demo style scss
@import "assets/sass/plugins";
@import "assets/sass/style";
@import "assets/sass/input-&-filter";
@import "assets/sass/button-style";
@import "vue-multiselect/dist/vue-multiselect.css";

.tf-btn-md,
.tf-btn-sm,
.tf-btn-lg {
    background-color: var(--brandcolor) !important;
    border: 1px solid var(--brandcolor);
    color: var(--brand-text-color) !important;

    &:is(:hover, :focus, :active, :visited) {
        background-color: var(--dark-brandcolor) !important;
        border: 1px solid var(--dark-brandcolor) !important;
    }

    &:disabled {
        opacity: 0.6;
        pointer-events: none;
    }
}

.tf-btn-primary {
    background-color: var(--brandcolor) !important;
    border: 1px solid var(--brandcolor);
    color: var(--brand-text-color) !important;

    &:is(:hover, :focus, :active, :visited) {
        background-color: var(--dark-brandcolor) !important;
        border: 1px solid var(--dark-brandcolor) !important;
    }

    &:disabled {
        opacity: 0.6;
        pointer-events: none;
    }
}

.el-tabs__active-bar {
    background-color: var(--dark-brandcolor) !important;
}

.el-tabs__item.is-active {
    color: var(--dark-brandcolor) !important;
}

.el-tabs__item:hover {
    color: var(--dark-brandcolor) !important;
}

.el-button--primary {
    background-color: var(--brandcolor) !important;
    border: 1px solid var(--brandcolor);
    color: var(--brand-text-color) !important;

    &:is(:hover, :focus, :active, :visited, :focus-visible) {
        background-color: var(--dark-brandcolor) !important;
        border: 1px solid var(--dark-brandcolor) !important;
        outline: none !important;
    }

    i {
        color: var(--brand-text-color) !important;
    }

    &:disabled {
        opacity: 0.6;
        pointer-events: none;
    }
}

.widget-drop-down {
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 2rem;
    margin-bottom: 2rem;
}

.dropdown-toggle {
    &:after {
        display: none !important;
    }
}

.loader-circle-primary {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin: 15px auto;
    position: relative;
    background: #d6dbe2;
    box-shadow: -18px 0 #d6dbe2, 18px 0 #d6dbe2;
    box-sizing: border-box;
    animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
    33% {
        background: #f3f6f9;
        box-shadow: -18px 0 #d6dbe2, 18px 0 #d6dbe2;
    }

    66% {
        background: #d6dbe2;
        box-shadow: -18px 0 #d6dbe2, 18px 0 #d6dbe2;
    }

    100% {
        background: #f3f6f9;
        box-shadow: -18px 0 #d6dbe2, 18px 0 #f3f6f9;
    }
}

:root {
    --toastify-color-success: #23a36d;
    --toastify-color-error: #de3439;
    --toastify-toast-width: 375px;

    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-error: var(--toastify-color-error);
    --toastify-font-family: Poppins;

    .Toastify__toast-container {
        width: var(--toastify-toast-width);
    }

    .Toastify__toast {
        font-size: 14px;
        color: #3f4254 !important;
    }

    .Toastify__close-button {
        position: absolute;
        right: 4%;
        top: 30%;
        bottom: 33%;
    }

    .Toastify__toast-body {
        margin: auto 3px;
        margin-right: 17px;
    }

    .Toastify__progress-bar--success {
        background: #23a36d;
    }

    .Toastify__progress-bar--error {
        background: #de3439;
    }
}

.sticky-box-left-side {
    position: fixed;
    border: 1px solid #404254;
    border-radius: 0 10px 10px 0;
    z-index: 9999;
    background-color: #fefefe;
    bottom: 10px;
    padding: 5px;
    border-left: none;

    @media (max-width: 1040px) {
        display: none;
    }
}

.review-text {
    color: #3e4256;
}

.full-width {
    width: 30px;
    height: 30px;
}

.center-content {
    justify-content: center;
    align-items: center;
    gap: 10px;
}
</style>
