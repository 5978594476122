import { useStore } from "@/store"
import { ActionTypes as WorkspaceActions } from "@/store/modules/workspace/actions"
import { ActionTypes as GlobalActions } from "@/store/modules/global/actions"
import useApiAuth from "@/services/apiAuth"
import useCatchHandler from "@/services/catchErrorHandler"
import ResellerHelper from "@/helper-functions/resellerHelperFunctions"
import { ref } from "vue"
import { useRouter } from "vue-router"
const { apiWorkspaceDetails } = useApiAuth()
const {
    checkResellers,
    setResellerName,
    setResellerDomain,
    setResellerStatus,
    setResellerManageWorkspaceStatus,
    setLinkToResellerSubscriptionStatus,
} = ResellerHelper()
const store = useStore()
const { handleError } = useCatchHandler()
const isWorkspaceDataLoading = ref(true)

export const useWorkspace = () => {
    const fetchWorkspaceData = async () => {
        isWorkspaceDataLoading.value = true
        try {
            const response = await apiWorkspaceDetails()
            if (response === -1) {
                isWorkspaceDataLoading.value = false
                return
            }
            store.dispatch(
                WorkspaceActions.SET_SUBSCRIPTION_EXPIRE_DATE,
                response.subscriptionExpireDate
            )
            store.dispatch(
                WorkspaceActions.SET_WORKSPACE_STATUS,
                response.status || ""
            )
            store.dispatch(
                WorkspaceActions.SET_IS_SUBSCRIPTION_NAME,
                response.subscriptionName
            )
            store.dispatch(
                WorkspaceActions.SET_IS_BILLING_ALLOWED,
                response.isBillingAllowed
            )
            store.dispatch(WorkspaceActions.SET_IS_TRIAL, response.isTrial)
            store.dispatch(
                WorkspaceActions.SET_DAYS_LEFT_TRIAL,
                response.daysLeftForTrial
            )
            store.dispatch(
                WorkspaceActions.SET_TRIAL_EXTENDED,
                response.trialExtended
            )
            store.dispatch(
                GlobalActions.SET_SHOW_PLANS_TAB,
                response.planSetting
            )
            store.dispatch(
                GlobalActions.SET_IS_DENYCREATEWORKSPACE,
                response.denyWorkspaceCreation
            )
            store.dispatch(
                GlobalActions.SET_IS_TESTLIFY_SUPPORT_LOGIN,
                response.isTestlifySupport
            )
            store.dispatch(GlobalActions.SET_IS_PAID_USER, response.isPaidUser)
            store.dispatch(
                GlobalActions.SET_RESELLER_VIEW_USAGE_STATUS,
                response.resellerViewUsage
            )
            store.dispatch(
                WorkspaceActions.SET_INTEGRATION_ADD_ON,
                response.integrationAddOn
            )
            store.dispatch(
                WorkspaceActions.SET_IS_APPSUMO_COUPON_APPLIED,
                response.isAppSumoCoupon
            )
            store.dispatch(
                WorkspaceActions.SET_WHITE_LABEL_ADD_ON,
                response.whiteLabelAddOn
            )
            store.dispatch(
                WorkspaceActions.SET_IS_PAY_PER_USAGE_PLAN,
                response.isPayPerUsagePlan
            )
            store.dispatch(
                WorkspaceActions.SET_IS_LEGACY_PLAN,
                response.isLegacyPlan
            )
            store.dispatch(
                WorkspaceActions.SET_WHITELABEL,
                response.isWhiteLabel
            )
            checkResellers(response.resellerType)
            setResellerStatus(response.isResellerActive)
            setResellerName(response.resellerName)
            setResellerDomain(response.candidateUrl)
            setResellerManageWorkspaceStatus(response.manageWorkspace)
            setLinkToResellerSubscriptionStatus(
                response.linkToResellerSubscription
            )
            store.dispatch(WorkspaceActions.SET_APPEARANCE, {
                brandColor: response.brandColor,
                logo: response.logo ? response.logo + "?" + Date.now() : null,
                favicon: response.favicon
                    ? response.favicon + "?" + Date.now()
                    : null,
                customImage: response.customImage
                    ? response.customImage + "?" + Date.now()
                    : null,
                brandTextColor: response?.brandTextColor || "#FFFFFF",
            })
            isWorkspaceDataLoading.value = false
            return response.organization
        } catch (error) {
            handleError(error)
            return -1
        }
    }

    return { fetchWorkspaceData, isWorkspaceDataLoading }
}
