<template>
    <ModalWrapper prop-size="md">
        <template #content>
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="custom-h2">
                        {{ $t("subscription_inactive_modal.title") }}
                    </h2>
                </div>
                <div class="modal-body">
                    <p class="custom-p">
                        {{ $t("subscription_inactive_modal.description") }}
                        <span class="underlinedText" style="color: #404254">
                            {{ supportEmail }}</span
                        >
                    </p>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="tf-outline-btn-md"
                        @click="closeModal()"
                    >
                        {{ $t("shareable_link_modal.close_modal") }}
                    </button>
                </div>
            </div>
        </template>
    </ModalWrapper>
</template>
<script lang="ts">
import { defineComponent, onBeforeUnmount, computed } from "vue"
import ModalWrapper from "src/view/modal/ModalWrapper.vue"
import { ActionTypes as GlobalActions } from "@/store/modules/global/actions"
import "vue3-toastify/dist/index.css"
import { store } from "../../store"
export default defineComponent({
    name: "SubscriptionInactiveModal",
    components: {
        ModalWrapper,
    },
    emits: ["close-native-modal"],
    setup(props, { emit }) {
        const supportEmail = computed(() => store.getters.getSupportEmail)
        const closeModal = () => {
            store.dispatch(
                GlobalActions.SHOW_SUBSCRIPTION_INACTIVE_MODAL,
                false
            )
            document.body.style.overflow = "auto"
        }

        const listener = (KeyboardEvent) => {
            if (KeyboardEvent.code === "Escape") {
                closeModal()
            }
        }
        window.addEventListener("keydown", listener)

        onBeforeUnmount(() => {
            window.removeEventListener("keydown", listener)
        })

        return {
            closeModal,
            supportEmail,
        }
    },
})
</script>
<style scoped lang="scss">
@import "@/assets/sass/core/components/_variables.scss";

.modal {
    background-color: rgb(24 28 50 / 30%) !important;
}

.modal-title {
    text-align: left;
}

.modal-dialog {
    max-width: 45% !important;
}

.cancel-btn {
    background: $white;
    border: 1px solid $black-600 !important;
    box-sizing: border-box;
    border-radius: 6px;
    margin-right: 20px;
}

.send-btn {
    background-color: $button-red;
    color: $white;
}

@media (max-width: 576px) {
    .modal-footer {
        flex-wrap: nowrap;
        justify-content: flex-end;
    }

    .cancel-btn {
        margin-right: 0.25rem;
    }
}
</style>
