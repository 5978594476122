import { ActionContext, ActionTree } from "vuex"
import { Mutations, MutationType } from "./mutations"
import type { State } from "./state"
import {
    CustomQuestionCartItem,
    GradingItem,
    ModifyingCodingQuestion,
    ModifyingCustomQuestion,
    ModifyingQualifyQuestion,
    QualifyingQuestionCartItem,
    TestCartItem,
    TypeAssessmentDetails,
    TypeCustomQuestionCart,
    TypeGradingList,
    TypeQualifyingQuestionCart,
    TypeSelectTestCart,
} from "./types"
import { RootState } from "/src/store"

export enum ActionTypes {
    SET_CREATE_ASSESSMENT = "SET_CREATE_ASSESSMENT",
    ADD_TEST_TO_CART = "ADD_TEST_TO_CART",
    UPDATE_TEST_LIBRARY_FROM_CART = "UPDATE_TEST_LIBRARY_FROM_CART",
    REMOVE_TEST_FROM_CART = "REMOVE_TEST_FROM_CART",
    CLEAR_CART_ITEMS = "CLEAR_CART_ITEMS",
    BULK_ADD_TEST_TO_CART = "BULK_ADD_TEST_TO_CART",
    RESET_ASSESSMENT = "RESET_ASSESSMENT",
    ADD_CUSTOM_QUESTION_TO_CART = "ADD_CUSTOM_QUESTION_TO_CART",
    REMOVE_CUSTOM_QUESTION_FROM_CART = "REMOVE_CUSTOM_QUESTION_FROM_CART",
    UPDATE_QUESTION_FROM_CART = "UPDATE_QUESTION_FROM_CART",
    UPDATE_TEST_FROM_CART = "UPDATE_TEST_FROM_CART",
    SET_QUALIFYING_QUESTION_TO_CART = "SET_QUALIFYING_QUESTION_TO_CART",
    BULK_ADD_QUESTION_TO_CART = "BULK_ADD_QUESTION_TO_CART",
    SET_WELCOME_VIDEO_PATH = "SET_WELCOME_VIDEO_PATH",
    SET_EXTERNAL_WELCOME_VIDEO_PATH = "SET_EXTERNAL_WELCOME_VIDEO_PATH",
    SET_ACTIVE_ACCORDION = "SET_ACTIVE_ACCORDION",
    ADD_QUALIFYING_QUESTION_TO_CART = "ADD_QUALIFYING_QUESTION_TO_CART",
    REMOVE_QUALIFYING_QUESTION_FROM_CART = "REMOVE_QUALIFYING_QUESTION_FROM_CART",
    BULK_ADD_QUALIFYING_QUESTION_TO_CART = "BULK_ADD_QUALIFYING_QUESTION_TO_CART",
    UPDATE_CUSTOM_QUESTION_TO_CART = "UPDATE_CUSTOM_QUESTION_TO_CART",
    UPDATE_QUALIFYING_QUESTION_TO_CART = "UPDATE_QUALIFYING_QUESTION_TO_CART",
    SET_ASSESSMENT_CONFIG = "SET_ASSESSMENT_CONFIG",
    SET_REPORT_SETTINGS = "SET_REPORT_SETTINGS",
    SET_ASSESSMENT_STATUS = "SET_ASSESSMENT_STATUS",
    RESET_ASSESSMENT_CONFIG = "RESET_ASSESSMENT_CONFIG",
    UPDATE_CODING_QUESTION_TO_CART = "UPDATE_CODING_QUESTION_TO_CART",
    SET_GRADING_LIST = "SET_GRADING_LIST",
    APPEND_GRADING_LIST = "APPEND_GRADING_LIST",
    REMOVE_GRADING_LIST_ITEM = "REMOVE_GRADING_LIST_ITEM",
    UPDATE_GRADING_LIST_ITEM = "UPDATE_GRADING_LIST_ITEM",
    UPDATE_OPTION_LIMIT = "UPDATE_OPTION_LIMIT",
    UPDATE_QUESTION_LIMIT = "UPDATE_QUESTION_LIMIT",
    SET_CUSTOMIZED_TEMPLATE = "SET_CUSTOMIZED_TEMPLATE",
    SET_REDIRECTED_FROM_PATH = "SET_REDIRECTED_FROM_PATH",
}

type ActionAugments = Omit<ActionContext<State, RootState>, "commit"> & {
    commit<K extends keyof Mutations>(
        key: K,
        payload?: Parameters<Mutations[K]>[1]
    ): ReturnType<Mutations[K]>
}

export type Actions = {
    [ActionTypes.SET_CREATE_ASSESSMENT](
        { commit }: ActionAugments,
        assessmentObject: TypeAssessmentDetails
    ): void
    [ActionTypes.ADD_TEST_TO_CART](
        { commit }: ActionAugments,
        cartItems: TestCartItem
    ): void
    [ActionTypes.BULK_ADD_TEST_TO_CART](
        { commit }: ActionAugments,
        cartItems: TypeSelectTestCart
    ): void
    [ActionTypes.BULK_ADD_QUESTION_TO_CART](
        { commit }: ActionAugments,
        cartItems: TypeCustomQuestionCart
    ): void
    [ActionTypes.REMOVE_TEST_FROM_CART](
        { commit }: ActionAugments,
        cartItemIndex: number
    ): void
    [ActionTypes.REMOVE_CUSTOM_QUESTION_FROM_CART](
        { commit }: ActionAugments,
        cartItemIndex: number
    ): void
    [ActionTypes.UPDATE_QUESTION_FROM_CART](
        { commit }: ActionAugments,
        newCartValue: any
    ): void
    [ActionTypes.SET_QUALIFYING_QUESTION_TO_CART](
        { commit }: ActionAugments,
        newCartValue: any
    ): void
    [ActionTypes.UPDATE_TEST_FROM_CART](
        { commit }: ActionAugments,
        newCartValue: any
    ): void
    [ActionTypes.CLEAR_CART_ITEMS]({ commit }: ActionAugments): void
    [ActionTypes.RESET_ASSESSMENT]({ commit }: ActionAugments): void
    [ActionTypes.ADD_CUSTOM_QUESTION_TO_CART](
        { commit }: ActionAugments,
        cartItems: CustomQuestionCartItem
    ): void
    [ActionTypes.SET_WELCOME_VIDEO_PATH](
        { commit }: ActionAugments,
        videoLink: String
    ): void
    [ActionTypes.SET_EXTERNAL_WELCOME_VIDEO_PATH](
        { commit }: ActionAugments,
        videoLink: String
    ): void
    [ActionTypes.SET_REDIRECTED_FROM_PATH](
        { commit }: ActionAugments,
        fullPathLink: String
    ): void
    [ActionTypes.SET_ACTIVE_ACCORDION](
        { commit }: ActionAugments,
        activeAccordion: String
    ): void
    [ActionTypes.ADD_QUALIFYING_QUESTION_TO_CART](
        { commit }: ActionAugments,
        cartItem: QualifyingQuestionCartItem
    ): void
    [ActionTypes.BULK_ADD_QUALIFYING_QUESTION_TO_CART](
        { commit }: ActionAugments,
        cartItem: TypeQualifyingQuestionCart
    ): void
    [ActionTypes.REMOVE_QUALIFYING_QUESTION_FROM_CART](
        { commit }: ActionAugments,
        cartItemIndex: number
    ): void
    [ActionTypes.UPDATE_CUSTOM_QUESTION_TO_CART](
        { commit }: ActionAugments,
        cartDetails: ModifyingCustomQuestion
    ): void
    [ActionTypes.UPDATE_CODING_QUESTION_TO_CART](
        { commit }: ActionAugments,
        cartDetails: ModifyingCodingQuestion
    ): void
    [ActionTypes.UPDATE_QUALIFYING_QUESTION_TO_CART](
        { commit }: ActionAugments,
        cartDetails: ModifyingQualifyQuestion
    ): void
    [ActionTypes.SET_ASSESSMENT_CONFIG](
        { commit }: ActionAugments,
        config: any
    ): void
    [ActionTypes.SET_REPORT_SETTINGS](
        { commit }: ActionAugments,
        reportObject: any
    ): void
    [ActionTypes.SET_ASSESSMENT_STATUS](
        { commit }: ActionAugments,
        status: string
    ): void
    [ActionTypes.RESET_ASSESSMENT_CONFIG](
        { commit }: ActionAugments,
        config: any
    ): void
    [ActionTypes.UPDATE_TEST_LIBRARY_FROM_CART](
        { commit }: ActionAugments,
        newCartValue: any
    ): void
    [ActionTypes.SET_GRADING_LIST](
        { commit }: ActionAugments,
        items: TypeGradingList
    ): void
    [ActionTypes.APPEND_GRADING_LIST](
        { commit }: ActionAugments,
        items: TypeGradingList
    ): void
    [ActionTypes.REMOVE_GRADING_LIST_ITEM](
        { commit }: ActionAugments,
        itemIndex: number
    ): void
    [ActionTypes.UPDATE_GRADING_LIST_ITEM](
        { commit }: ActionAugments,
        item: { gradingItem: GradingItem; index: number }
    ): void
    [ActionTypes.UPDATE_QUESTION_LIMIT](
        { commit }: ActionAugments,
        limit: number
    ): void
    [ActionTypes.UPDATE_OPTION_LIMIT](
        { commit }: ActionAugments,
        limit: number
    ): void
    [ActionTypes.SET_CUSTOMIZED_TEMPLATE](
        { commit }: ActionAugments,
        template: any
    ): void
}

export const actions: ActionTree<State, RootState> & Actions = {
    [ActionTypes.SET_CREATE_ASSESSMENT]({ commit }, assessmentObject) {
        commit(MutationType.SET_CREATE_ASSESSMENT, assessmentObject)
    },
    [ActionTypes.ADD_TEST_TO_CART]({ commit }, cartItems) {
        commit(MutationType.ADD_TEST_TO_CART, cartItems)
    },
    [ActionTypes.BULK_ADD_TEST_TO_CART]({ commit }, cartItems) {
        commit(MutationType.BULK_ADD_TEST_TO_CART, cartItems)
    },
    [ActionTypes.BULK_ADD_QUESTION_TO_CART]({ commit }, cartItems) {
        commit(MutationType.BULK_ADD_QUESTION_TO_CART, cartItems)
    },
    [ActionTypes.REMOVE_TEST_FROM_CART]({ commit }, cartItemIndex) {
        commit(MutationType.REMOVE_TEST_FROM_CART, cartItemIndex)
    },
    [ActionTypes.CLEAR_CART_ITEMS]({ commit }) {
        commit(MutationType.CLEAR_CART_ITEMS)
    },
    [ActionTypes.UPDATE_TEST_LIBRARY_FROM_CART]({ commit }, newCartValue) {
        commit(MutationType.UPDATE_TEST_LIBRARY_FROM_CART, newCartValue)
    },
    [ActionTypes.RESET_ASSESSMENT]({ commit, dispatch }) {
        dispatch(ActionTypes.CLEAR_CART_ITEMS)
        dispatch(ActionTypes.SET_CREATE_ASSESSMENT, {
            id: "",
            title: "",
            jobRoleId: "",
            otherJobRole: "",
            customQuestionTimerType: "",
            customQuestionTime: "",
            assessmentDescription: "",
            supportedLanguages: ["all"],
        })
    },
    [ActionTypes.ADD_CUSTOM_QUESTION_TO_CART]({ commit }, cartItems) {
        commit(MutationType.ADD_CUSTOM_QUESTION_TO_CART, cartItems)
    },
    [ActionTypes.REMOVE_CUSTOM_QUESTION_FROM_CART]({ commit }, cartItemIndex) {
        commit(MutationType.REMOVE_CUSTOM_QUESTION_FROM_CART, cartItemIndex)
    },
    [ActionTypes.UPDATE_QUESTION_FROM_CART]({ commit }, newCartValue) {
        commit(MutationType.UPDATE_QUESTION_FROM_CART, newCartValue)
    },
    [ActionTypes.SET_QUALIFYING_QUESTION_TO_CART]({ commit }, newCartValue) {
        commit(MutationType.SET_QUALIFYING_QUESTION_TO_CART, newCartValue)
    },
    [ActionTypes.UPDATE_TEST_FROM_CART]({ commit }, newCartValue) {
        commit(MutationType.UPDATE_TEST_FROM_CART, newCartValue)
    },
    [ActionTypes.SET_WELCOME_VIDEO_PATH]({ commit }, videoLink) {
        commit(MutationType.SET_WELCOME_VIDEO_PATH, videoLink)
    },
    [ActionTypes.SET_EXTERNAL_WELCOME_VIDEO_PATH]({ commit }, videoLink) {
        commit(MutationType.SET_EXTERNAL_WELCOME_VIDEO_PATH, videoLink)
    },
    [ActionTypes.SET_REDIRECTED_FROM_PATH]({ commit }, fullPathLink) {
        commit(MutationType.SET_REDIRECTED_FROM_PATH, fullPathLink)
    },
    [ActionTypes.SET_ACTIVE_ACCORDION]({ commit }, activeAccordion) {
        if (activeAccordion) {
            commit(MutationType.SET_ACTIVE_ACCORDION, activeAccordion)
        }
    },
    [ActionTypes.ADD_QUALIFYING_QUESTION_TO_CART]({ commit }, cartItem) {
        commit(MutationType.ADD_QUALIFYING_QUESTION_TO_CART, cartItem)
    },
    [ActionTypes.REMOVE_QUALIFYING_QUESTION_FROM_CART](
        { commit },
        cartItemIndex
    ) {
        commit(MutationType.REMOVE_QUALIFYING_QUESTION_FROM_CART, cartItemIndex)
    },
    [ActionTypes.BULK_ADD_QUALIFYING_QUESTION_TO_CART]({ commit }, cartItem) {
        commit(MutationType.BULK_ADD_QUALIFYING_QUESTION_TO_CART, cartItem)
    },
    [ActionTypes.UPDATE_CUSTOM_QUESTION_TO_CART]({ commit }, cartDetails) {
        commit(MutationType.UPDATE_CUSTOM_QUESTION_TO_CART, cartDetails)
    },
    [ActionTypes.UPDATE_CODING_QUESTION_TO_CART]({ commit }, cartDetails) {
        commit(MutationType.UPDATE_CODING_QUESTION_TO_CART, cartDetails)
    },
    [ActionTypes.UPDATE_QUALIFYING_QUESTION_TO_CART]({ commit }, cartDetails) {
        commit(MutationType.UPDATE_QUALIFYING_QUESTION_TO_CART, cartDetails)
    },
    [ActionTypes.SET_ASSESSMENT_CONFIG]({ commit }, config) {
        commit(MutationType.SET_ASSESSMENT_CONFIG, config)
    },
    [ActionTypes.SET_REPORT_SETTINGS]({ commit }, reportObject) {
        commit(MutationType.SET_REPORT_SETTINGS, reportObject)
    },
    [ActionTypes.RESET_ASSESSMENT_CONFIG]({ commit }, config) {
        commit(MutationType.RESET_ASSESSMENT_CONFIG, config)
    },
    [ActionTypes.SET_ASSESSMENT_STATUS]({ commit }, status) {
        commit(MutationType.SET_ASSESSMENT_STATUS, status)
    },
    [ActionTypes.SET_GRADING_LIST]({ commit }, items) {
        commit(MutationType.SET_GRADING_LIST, items)
    },
    [ActionTypes.APPEND_GRADING_LIST]({ commit }, items) {
        commit(MutationType.APPEND_GRADING_LIST, items)
    },
    [ActionTypes.REMOVE_GRADING_LIST_ITEM]({ commit }, item) {
        commit(MutationType.REMOVE_GRADING_LIST_ITEM, item)
    },
    [ActionTypes.UPDATE_GRADING_LIST_ITEM]({ commit }, item) {
        commit(MutationType.UPDATE_GRADING_LIST_ITEM, item)
    },
    [ActionTypes.UPDATE_QUESTION_LIMIT]({ commit }, limit) {
        commit(MutationType.UPDATE_QUESTION_LIMIT, limit)
    },
    [ActionTypes.UPDATE_OPTION_LIMIT]({ commit }, limit) {
        commit(MutationType.UPDATE_OPTION_LIMIT, limit)
    },
    [ActionTypes.SET_CUSTOMIZED_TEMPLATE]({ commit }, template) {
        commit(MutationType.SET_CUSTOMIZED_TEMPLATE, template)
    },
}
