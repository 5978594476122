import {
    TypeAccessLogin,
    TypeAccessRegistration,
    ApiRequestHeader,
    TypeAuthUser,
    TypeDefaultLoginDetails,
    TypeRedirectAfterLogin,
    TypeTeamMember,
} from "./types"
import { GetterTree } from "vuex"
import { State } from "./state"
import { RootState } from "@/store"

export type Getters = {
    getAuthUser(state: State): TypeAuthUser
    getAuthUserFullName(state: State): string
    getAccessRegistration(state: State): TypeAccessRegistration
    getAccessLogin(state: State): TypeAccessLogin
    getDefaultAccessLoginDetails(state: State): TypeDefaultLoginDetails
    getSessionKey(state: State): string
    getApiRequestHeader(state: State): ApiRequestHeader
    getAuthUserRole(state: State): string
    getUserRegistered(state: State): boolean
    getShowQuestions(state: State): boolean
    getRedirectAfterLogin(state: State): TypeRedirectAfterLogin
    getAuthUserLanguage(state: State): string
    getTeamMembers(state: State): TypeTeamMember
    getUserRoleId(state: State): string
}

export const getters: GetterTree<State, RootState> & Getters = {
    getAuthUser(state) {
        return state.authUser
    },
    getAuthUserFullName(state) {
        return `${state.authUser.firstName} ${state.authUser.lastName}`
    },
    getAccessRegistration(state) {
        return state.accessRegistration
    },
    getAccessLogin(state) {
        return state.accessLogin
    },
    getDefaultAccessLoginDetails(state) {
        return state.defaultLoginDetails
    },
    getTeamMembers(state) {
        return state.teamMembers
    },
    getSessionKey(state) {
        return state.authUser.sessionAccessKey
    },
    getApiRequestHeader(state) {
        return {
            headers: {
                Authorization: `Bearer ${state.authUser.sessionAccessKey}`,
            },
        }
    },
    getAuthUserRole(state) {
        return state.authUser.role
    },
    getUserRegistered(state: State): boolean {
        return state.isUserRegistered
    },
    getShowQuestions(state: State): boolean {
        return state.authUser.showQuestions
    },
    getRedirectAfterLogin(state) {
        return state.redirectAfterLogin
    },
    getAuthUserLanguage(state) {
        return state.authUser.defaultLanguage
    },
    getUserRoleId(state: State): string {
        return state.userRoleId
    },
}
