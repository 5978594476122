import Vuex, { createStore } from "vuex"
import createPersistedState from "vuex-persistedstate"
import allowPersistedPaths from "./allowPersist"
import {
    AuthStore,
    store as Auth,
    State as AuthState,
} from "@/store/modules/auth"
import {
    GlobalStore,
    store as Global,
    State as GlobalState,
} from "@/store/modules/global"
import {
    PermissionStore,
    store as Permission,
    State as PermissionState,
} from "@/store/modules/permissions"

import {
    AssessmentStore,
    store as Assessment,
    State as AssessmentState,
} from "@/store/modules/assessment"
import {
    WorkspaceStore,
    store as Workspace,
    State as WorkspaceState,
} from "@/store/modules/workspace"
import {
    TestStore,
    store as Test,
    State as TestState,
} from "@/store/modules/test"
import trackerVuex from "@openreplay/tracker-vuex"
import Tracker from "@openreplay/tracker"
new Vuex.Store({
    plugins: [createPersistedState()],
})

const plugins = []
if (
    !window.location.href.includes("localhost") &&
    !window.location.href.includes("vercel")
) {
    window.tracker = new Tracker({
        projectKey: "BLwMqjBefRRiZFDdjL8t",
        network: {
            sessionTokenHeader: false,
            failuresOnly: false,
            ignoreHeaders: ["Cookie", "Set-Cookie", "Authorization"],
            capturePayload: true,
            captureInIframes: true,
        },
        ingestPoint: "https://openreplay.testlify.com/ingest",
        obscureTextEmails: false,
        obscureInputEmails: false,
        defaultInputMode: 0,
        obscureInputDates: false,
        obscureTextNumbers: false,
        __DISABLE_SECURE_MODE: true,
    })
    plugins.push(window.tracker.use(trackerVuex()))
}
// Plug in local storage or custom QueryParamStorage(isEmbed is true) based persistence
plugins.push(
    createPersistedState({
        storage: window.localStorage,
        fetchBeforeUse: true,
        key: "testlify_storage",
        paths: allowPersistedPaths,
    })
)

export const store = createStore({
    plugins,
    modules: {
        // these names are referred in the "allowPersist.ts" file
        Auth,
        Global,
        Assessment,
        Workspace,
        Test,
        Permission,
    },
})

export type RootState = {
    Auth: AuthState
    Global: GlobalState
    Assessment: AssessmentState
    Workspace: WorkspaceState
    Test: TestState
    Permission: PermissionState
}

export const rootState: RootState = store.state as RootState

export type Store = AuthStore<Pick<RootState, "Auth">> &
    GlobalStore<Pick<RootState, "Global">> &
    AssessmentStore<Pick<RootState, "Assessment">> &
    WorkspaceStore<Pick<RootState, "Workspace">> &
    TestStore<Pick<RootState, "Test">> &
    PermissionStore<Pick<RootState, "Permission">>

export function useStore() {
    return store as Store
}
