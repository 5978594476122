import useCatchHandler from "@/services/catchErrorHandler"
import useOAuth from "@/services/oauth"
import { useStore } from "@/store"
import { ActionTypes as PermissionActions } from "@/store/modules/permissions/actions"
import axios from "axios"
import { computed } from "vue"

export default function useApiRoles() {
    const { handleError } = useCatchHandler()
    const { API_PREFIX } = useOAuth()

    const store = useStore()
    const getApiRequestHeader = computed(
        () => store.getters.getApiRequestHeader
    )

    const apiGetRoles = async (
        search: string,
        colName: string = "",
        inOrder: string = ""
    ) => {
        inOrder =
            inOrder === "ascending"
                ? "asc"
                : inOrder === "descending"
                ? "desc"
                : ""
        try {
            let response: any = await axios.get(
                `${API_PREFIX}/workspace/roles?q=${search}&colName=${colName}&inOrder=${inOrder}`,
                getApiRequestHeader.value
            )
            return response
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiGetSingleRole = async (id: string) => {
        try {
            let response: any = await axios.get(
                `${API_PREFIX}/workspace/role/${id}`,
                getApiRequestHeader.value
            )
            return response
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiAddRole = async (name, permissions) => {
        try {
            const body = {
                role: name,
                permissions: {
                    "ASSESSMENT-CREATE": permissions.createAssessment,
                    "ASSESSMENT-EDIT": permissions.editAssessment,
                    "ASSESSMENT-DELETE": permissions.deleteAssessment,
                    "ASSESSMENT-ARCHIVE": permissions.archiveAssessment,
                    "ASSESSMENT-COPY": permissions.copyAssessment,
                    "CANDIDATE-INVITE": permissions.inviteCandidate,
                    "CANDIDATE-DELETE": permissions.deleteCandidate,
                    USER: permissions.usersSetting,
                    "SETTINGS-USER-ADD": permissions.addUser,
                    "SETTINGS-USER-CHANGE-ROLE": permissions.changeRole,
                    "SETTINGS-USER-CHANGE-STATUS":
                        permissions.activeDeactiveUser,
                    "SETTINGS-ROLES-AND-PERMISSIONS": permissions.role,
                    "BILLING-MANAGE-SUBSCRIPTION":
                        permissions.manageSubscription,
                    DEVELOPER: permissions.developer,
                    "WHITE-LABEL": permissions.whiteLabel,
                },
            }
            return await axios.post(
                `${API_PREFIX}/workspace/role`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiChangePermissions = async (
        id: string,
        name: string,
        permissions: any
    ) => {
        try {
            const body = {
                role: name,
                permissions: {
                    "ASSESSMENT-CREATE": permissions.createAssessment,
                    "ASSESSMENT-EDIT": permissions.editAssessment,
                    "ASSESSMENT-DELETE": permissions.deleteAssessment,
                    "ASSESSMENT-ARCHIVE": permissions.archiveAssessment,
                    "ASSESSMENT-COPY": permissions.copyAssessment,
                    "CANDIDATE-INVITE": permissions.inviteCandidate,
                    "CANDIDATE-DELETE": permissions.deleteCandidate,
                    USER: permissions.usersSetting,
                    "SETTINGS-USER-ADD": permissions.addUser,
                    "SETTINGS-USER-CHANGE-ROLE": permissions.changeRole,
                    "SETTINGS-USER-CHANGE-STATUS":
                        permissions.activeDeactiveUser,
                    "SETTINGS-ROLES-AND-PERMISSIONS": permissions.role,
                    "BILLING-MANAGE-SUBSCRIPTION":
                        permissions.manageSubscription,
                    DEVELOPER: permissions.developer,
                    "WHITE-LABEL": permissions.whiteLabel,
                },
            }
            return await axios.put(
                `${API_PREFIX}/workspace/role/${id}`,
                body,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    const apiDeleteRole = async (id: string) => {
        try {
            return await axios.delete(
                `${API_PREFIX}/workspace/role/${id}`,
                getApiRequestHeader.value
            )
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }
    const apiGetUserPermission = async () => {
        try {
            let response: any = await axios.get(
                `${API_PREFIX}/workspace/user/permissions`,
                getApiRequestHeader.value
            )

            store.dispatch(
                PermissionActions.SET_CREATE_ASSESSMENT_PERMISSION,
                response.data["ASSESSMENT-CREATE"]
            )
            store.dispatch(
                PermissionActions.SET_EDIT_ASSESSMENT_PERMISSION,
                response.data["ASSESSMENT-EDIT"]
            )
            store.dispatch(
                PermissionActions.SET_DELETE_ASSESSMENT_PERMISSION,
                response.data["ASSESSMENT-DELETE"]
            )
            store.dispatch(
                PermissionActions.SET_ARCHIVE_ASSESSMENT_PERMISSION,
                response.data["ASSESSMENT-ARCHIVE"]
            )
            store.dispatch(
                PermissionActions.SET_COPY_ASSESSMENT_PERMISSION,
                response.data["ASSESSMENT-COPY"]
            )
            store.dispatch(
                PermissionActions.SET_INVITE_CANDIDATE_PERMISSION,
                response.data["CANDIDATE-INVITE"]
            )
            store.dispatch(
                PermissionActions.SET_DELETE_CANDIDATE_PERMISSION,
                response.data["CANDIDATE-DELETE"]
            )
            store.dispatch(
                PermissionActions.SET_USER_PERMISSION,
                response.data["USER"]
            )
            store.dispatch(
                PermissionActions.SET_ADD_USER_PERMISSION,
                response.data["SETTINGS-USER-ADD"]
            )
            store.dispatch(
                PermissionActions.SET_CHANGE_USER_ROLE_PERMISSION,
                response.data["SETTINGS-USER-CHANGE-ROLE"]
            )
            store.dispatch(
                PermissionActions.SET_CHANGE_USER_STATUS_PERMISSION,
                response.data["SETTINGS-USER-CHANGE-STATUS"]
            )
            store.dispatch(
                PermissionActions.SET_ROLES_PERMISSION,
                response.data["SETTINGS-ROLES-AND-PERMISSIONS"]
            )
            store.dispatch(
                PermissionActions.SET_DEVELOPER_PERMISSION,
                response.data["DEVELOPER"]
            )
            store.dispatch(
                PermissionActions.SET_WHITE_LABEL_PERMISSION,
                response.data["WHITE-LABEL"]
            )
            store.dispatch(
                PermissionActions.SET_SUBSCRIPTION_PERMISSION,
                response.data["BILLING-MANAGE-SUBSCRIPTION"]
            )

            return response.data
        } catch ({ response }) {
            handleError(response)
            return -1
        }
    }

    return {
        apiGetRoles,
        apiAddRole,
        apiDeleteRole,
        apiChangePermissions,
        apiGetUserPermission,
        apiGetSingleRole,
    }
}
