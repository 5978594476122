import {
    assessmentConfig,
    TypeAssessmentDetails,
    TypeCustomQuestionCart,
    TypeGradingList,
    TypeQualifyingQuestionCart,
    TypeReportSetting,
    TypeSelectTestCart,
} from "./types"
import {
    DEFAULT_ASSESSMENT_CONFIG,
    DEFAULT_SHORTLIST_TEMPLATE,
} from "/src/core/constants"

export type State = {
    assessmentDetails: TypeAssessmentDetails
    selectTestCart: TypeSelectTestCart
    customQuestionCart: TypeCustomQuestionCart
    welcomeVideoPath: String
    externalWelcomeVideoLink: String
    assessmentStatus: String
    qualifyingQuestionCart: TypeQualifyingQuestionCart
    activeAccordion: String
    assessmentConfig: assessmentConfig
    gradingItems: TypeGradingList
    reportSettings: TypeReportSetting
    questionLimit: Number
    optionLimit: Number
    customizedEmailTemplates: {
        shortlisted: String
    }
    redirectedFrom: String
}

export const state: State = {
    assessmentDetails: {
        id: "",
        title: "",
        jobRoleId: "",
        otherJobRole: "",
        jobRoleName: "",
        customQuestionTimerType: "",
        customQuestionTime: 0,
        assessmentDescription: "",
        supportedLanguages: ["all"],
    },
    welcomeVideoPath: "",
    externalWelcomeVideoLink: "",
    selectTestCart: [],
    customQuestionCart: [],
    qualifyingQuestionCart: [],
    activeAccordion: "1",
    assessmentConfig: DEFAULT_ASSESSMENT_CONFIG,
    assessmentStatus: "",
    gradingItems: [],
    reportSettings: {
        showOverview: false,
        candidateInfo: false,
        showAiInsight: false,
        showSummary: false,
        showHiddenInformation: false,
        performanceAnalytics: false,
        proctoringInfo: false,
        testLibrary: false,
        customQuestion: false,
        statusLogs: false,
        testLibrarySection: {
            expectedAnswerTL: true,
            questionAsked: true,
            showTLAiResponse: true,
            showAiInterpretation: true,
        },
        questionSection: {
            expectedAnswerCQ: false,
            showCQAiResponse: false,
        },
    },
    questionLimit: 0,
    optionLimit: 0,
    customizedEmailTemplates: {
        shortlisted: DEFAULT_SHORTLIST_TEMPLATE,
    },
    redirectedFrom: "",
}
