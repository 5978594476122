<template>
    <div class="modal">
        <div :class="[animationClass, modalSize]" class="dialog-modal">
            <div class="content-modal">
                <div
                    v-if="isSlotHeaderPresent"
                    class="header-modal align-items-center"
                >
                    <slot name="header"></slot>
                </div>
                <div v-if="isSlotContentPresent">
                    <slot name="content"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, onBeforeUnmount } from "vue"

export default defineComponent({
    name: "ModalWrapper",
    props: {
        propSize: {
            type: String,
            required: false,
        },
        bodyOverflowHidden: {
            type: Boolean,
            required: false,
        },
        shouldEscapeModal: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    setup(props, { emit, slots }) {
        const isSlotHeaderPresent = computed(() => slots.header)
        const isSlotContentPresent = computed(() => slots.content)
        const modalSize = computed(() => {
            if (props.propSize === "sm") {
                return "modal-small"
            } else if (props.propSize === "md") {
                return "modal-medium"
            } else if (props.propSize === "ex-lrg") {
                return "modal-extra-large"
            } else {
                return "modal-large"
            }
        })

        const animationClass = computed(() => {
            return "animateOpen"
        })
        const closeModal = () => {
            emit("close-native-modal")
            if (props.bodyOverflowHidden) {
                document.body.style.overflow = "hidden"
            } else {
                document.body.style.overflow = "auto"
            }
        }

        const listener = (KeyboardEvent) => {
            if (KeyboardEvent.code === "Escape") {
                emit("close-native-modal")
            }
        }
        if (props.shouldEscapeModal) {
            window.addEventListener("keydown", listener)

            onBeforeUnmount(() => {
                if (props.bodyOverflowHidden) {
                    document.body.style.overflow = "hidden"
                } else {
                    document.body.style.overflow = "auto"
                }
                window.removeEventListener("keydown", listener)
            })
        }

        return {
            animationClass,
            closeModal,
            isSlotHeaderPresent,
            isSlotContentPresent,
            modalSize,
        }
    },
})
</script>
<style scoped lang="scss">
@import "src/assets/sass/core/components/_variables.scss";

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    z-index: 1050;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: baseline;
    cursor: pointer;
    visibility: visible;
    overflow-x: hidden;
    overflow-y: auto;
    align-items: center !important;
}

.dialog-modal {
    position: relative;
    border-radius: 12px;
    background: $white;
    overflow: auto;
    cursor: default;
    //width:50%;
    margin: 1.75rem auto;
}

.content-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    //width: 100%;
    pointer-events: auto;
    background-color: $white;
    background-clip: padding-box;
    outline: 0;
}

.animateOpen {
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.header-modal {
    padding: 1.75rem 2.75rem 0 2.75rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5rem;
}

.modal-small {
    width: 95%;
    max-width: 350px;
}

.modal-medium {
    width: 95%;
    max-width: 600px;
}

.modal-large {
    width: 95%;
    max-width: 800px;
}
.modal-extra-large {
    width: 95%;
    max-width: 1140px;
}
@media (max-width: 1152px) {
    .edit-link-modal {
        .modal-medium {
            max-width: 500px !important;
        }
    }
}
</style>
