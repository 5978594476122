export type State = {
    assessmentCreate: boolean
    assessmentEdit: boolean
    assessmentDelete: boolean
    assessmentArchive: boolean
    assessmentCopy: boolean
    candidateInvite: boolean
    candidateDelete: boolean
    settingsUser: boolean
    settingsUserAdd: boolean
    settingsUserChangeRole: boolean
    settingsUserChangeStatus: boolean
    settingsRolesAndPermissions: boolean
    settingsDeveloperPermission: boolean
    settingsWhiteLabelPermission: boolean
    billingManageSubscription: boolean
}

export const state: State = {
    assessmentCreate: true,
    assessmentEdit: true,
    assessmentDelete: true,
    assessmentArchive: true,
    assessmentCopy: true,
    candidateInvite: true,
    candidateDelete: true,
    settingsUser: true,
    settingsUserAdd: true,
    settingsUserChangeRole: true,
    settingsUserChangeStatus: true,
    settingsRolesAndPermissions: true,
    billingManageSubscription: true,
    settingsDeveloperPermission: true,
    settingsWhiteLabelPermission: true,
}
