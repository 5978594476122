import {
    PRIVACY_POLICY_URL,
    SAWAEED_EMPLOYER_DOMAIN,
    TERMS_CONDITION_URL,
} from "@/core/constants"
import helperFunction from "@/helper-functions/helperFunctions"
import useApiInviteMember from "@/services/apiInviteMember"
import { useStore } from "@/store"
import { ActionTypes as AssessmentActions } from "@/store/modules/assessment/actions"
import { ActionTypes as AuthActions } from "@/store/modules/auth/actions"
import { computed, ref } from "vue"
import * as VueRouter from "vue-router"
import moment from "moment/moment"

const store = useStore()
const {
    setSessionCookie,
    getSessionCookie,
    checkResellerSupport,
    isTestlifyDomain,
} = helperFunction()
const { apiGetLongLinkUrl } = useApiInviteMember()
const accessRegistrationData = computed(
    () => store.getters.getAccessRegistration
)
const isReseller = computed(() => store.getters.getResellerStatus)
const isWhiteLabel = computed(() => store.getters.getWhiteLabelStatus)
const termsUrl = computed(() => store.getters.getTermsUrl)
const privacyUrl = computed(() => store.getters.getPrivacyUrl)
const getAuthUser = computed(() => store.getters.getAuthUser)
const showQuestions = computed(() => store.getters.getShowQuestions)
const denySwitchAccess = computed(() => store.getters.getDenyCreateWorkspace)
const userpermissions = computed(() => store.getters.getUserPermission)
const rolepermissions = computed(() => store.getters.getRolesPermission)
const subscriptionName = computed(() => store.getters.subscriptionName)
const subscriptionExpireDate = computed(
    () => store.getters.getSubscriptionExpireDate
)
const isResellerActive = computed(() => store.getters.getisResellerActive)
const getResellerManageWorkspaceStatus = computed(
    () => store.getters.getResellerManageWorkspaceStatus
)
const getLinkToResellerSubscriptionStatus = computed(
    () => store.getters.getLinkToResellerSubscriptionStatus
)

const isCreateAssessmentAllowed = computed(
    () => store.getters.getCreateAssessmentPermission
)
const isSubscriptionAccess = computed(
    () => store.getters.getSubscriptionPermission
)
function checkIfHNRorTestlify() {
    const emailDomain = getAuthUser.value?.email.split("@")[1]
    if (
        emailDomain === "testlify.com" ||
        emailDomain === "hnrtech.com" ||
        window.location.href.includes("localhost") ||
        window.location.href.includes("vercel")
    ) {
        return true
    } else {
        return checkResellerRoutes()
    }
}
const isLifetime = computed(() => {
    return (
        subscriptionName.value.includes("Lifetime") ||
        subscriptionName.value.includes("LTD")
    )
})
//restricting Ampowered reseller from switching
function checkIfSwitchAllowed() {
    const isSupport = checkResellerSupport(getAuthUser.value?.email)
    if (isSupport || !denySwitchAccess.value) {
        return true
    }
    return { name: "AssessmentMain" }
}

function checkAssessmentCreationPermission() {
    if (isCreateAssessmentAllowed.value) {
        return true
    }
    return { name: "404" }
}

function checkBillingPermission() {
    if (isSubscriptionAccess.value) {
        return true
    }
    return { name: "404" }
}

function checkSubscriptionStatus() {
    const todayDate = moment(new Date().toUTCString())
    const expiryDate = moment(
        new Date(subscriptionExpireDate.value.toString()).toUTCString()
    )
    let preciseDay = ref(expiryDate.diff(todayDate, "days", true))
    let remainingDays = ref(Number(preciseDay.value))
    if (
        isResellerActive.value &&
        !getLinkToResellerSubscriptionStatus.value &&
        !getResellerManageWorkspaceStatus.value &&
        (subscriptionExpireDate.value === "" || remainingDays.value <= 0)
    ) {
        return true
    }
    return { name: "AssessmentMain" }
}

function checkResellerRoutes() {
    if (getAuthUser.value?.email === "contact@join.com") {
        return true
    } else if (isReseller.value) {
        return { name: "404" }
    }
    return true
}

function checkRolesPermission(value: any) {
    let routeTab = value?.params?.tab
    if (routeTab === "users" && !userpermissions.value) {
        return { name: "404" }
    } else if (routeTab === "roles" && !rolepermissions.value) {
        return { name: "404" }
    }
    return true
}

function checkShowQuestions() {
    if ((!isReseller.value || !isLifetime.value) && showQuestions) {
        return true
    } else {
        return { name: "404" }
    }
}

function checkWorkspace() {
    //get access key
    const accessKey = accessRegistrationData.value["accessKey"]
    // check if accessKey is empty or not,
    // if empty then redirect to Register page. If not then, redirect to CreateWorkspace
    if (accessKey !== "") {
        return true
    }
    return { name: "Register" }
}

function checkBillingAllowed() {
    // @ts-ignore
    if (store.getters.isBillingAllowed) {
        return true
    }
    return { name: "AssessmentMain" }
}

function checkIsSawaeed() {
    if (window.location.origin === SAWAEED_EMPLOYER_DOMAIN) {
        return { name: "Login" }
    } else return true
}

const routes: VueRouter.RouteRecordRaw[] = [
    {
        path: "/restricted-access",
        name: "RestrictedAccess",
        component: () =>
            import("@/view/pages/general/permission/RestrictedAccess.vue"),
        meta: {
            isAuth: true,
        },
    },
    {
        path: "/",
        redirect: "/login",
        meta: {},
    },
    {
        path: "/reports/:reportType/:id",
        name: "DownloadAsset",
        component: () => import("@/view/pages/general/DownloadAsset.vue"),
        meta: {
            isBilling: true,
        },
    },
    {
        path: "/saml",
        name: "SSO",
        component: () =>
            import("@/view/pages/general/auth-forms/SignUpsso.vue"),
        meta: {
            isAuth: true, // allowed to access this route only if user is logged out
        },
    },
    {
        path: "/sso/:ssoTeamSlug",
        name: "SSOTeamSlug",
        component: () =>
            import("@/view/pages/general/auth-forms/SignUpsso.vue"),
        meta: {
            isAuth: true, // allowed to access this route only if user is logged out
        },
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("@/view/pages/general/auth-forms/Login.vue"),
        meta: {
            isAuth: true, // allowed to access this route only if user is logged out
        },
        beforeEnter: (to: any, from: any, next: any) => {
            if (to.query.action && to.query.action === "clear") {
                store.dispatch(AuthActions.EMPTY_API_ACCESS_LOGIN)
                return next()
            }

            const resellerHosts = {
                staging: "assessments.join-staging.com",
                production: "assessments.join.com",
            }
            const { accessToken, wu } = to.query
            if (accessToken && wu) {
                store.dispatch(AuthActions.LOG_OUT_WITHOUT_REDIRECTION)
                setSessionCookie(accessToken)
                setSessionCookie(wu, "workspaceUrl")
                return next({ name: "IntermediateLogin" })
            } else {
                if (window.location.host === resellerHosts?.staging) {
                    return window.open(
                        `https://join-staging.com/sso/idp/testlify/initiate`,
                        "_self"
                    )
                }
                if (window.location.host === resellerHosts?.production) {
                    return window.open(
                        `https://sso.join.com/idp/testlify/initiate`,
                        "_self"
                    )
                }
            }
            return next()
        },
    },
    {
        path: "/two-factor",
        name: "TwoFactorVerification",
        component: () =>
            import("@/view/pages/general/auth-forms/TwoFactorVerification.vue"),
        meta: {
            isAuth: true,
        },
    },
    {
        path: "/recover-2fa",
        name: "RecoverTwoFactorAuthentication",
        component: () =>
            import(
                "@/view/pages/general/auth-forms/RecoverTwoFactorAuthentication.vue"
            ),
        meta: {
            isAuth: true,
        },
    },
    {
        path: "/2fa",
        name: "TwoFactorSetupModal",
        component: () => import("@/view/modal/TwoFactorSetupModal.vue"),
        meta: {
            isAuth: true,
        },
    },
    {
        path: "/register",
        name: "Register",
        component: () =>
            import("@/view/pages/general/auth-forms/RegistrationPage.vue"),
        meta: {
            isAuth: true,
        },
        beforeEnter: [checkIsSawaeed],
    },
    {
        path: "/invitation-expired",
        name: "InvitationExpired",
        component: () => import("@/view/pages/general/InvitationExpired.vue"),
    },
    // this path will actually look like this- /register/invite?token=invite_key
    {
        path: "/register/invite/:shortId?",
        name: "InviteeRegister",
        props: true,
        component: () =>
            import(
                "@/view/pages/general/auth-forms/InviteeRegistrationPage.vue"
            ),
        meta: {
            isAuth: true,
        },
        beforeEnter: async (to, from, next) => {
            // Check if there's already a token in the current route
            if (to.query.token) {
                // Continue to the route with the token
                next()
            } else {
                const r = to.query.r
                if (r && r === "2") {
                    const region = "Saudi Arabia"
                    localStorage.setItem("selectedDataRegion", region)
                    localStorage.setItem("manuallySelectedRegion", "false")
                }
                // Fetch long link URL and extract token
                const response = await apiGetLongLinkUrl(
                    to.params.shortId.toString()
                )
                if (
                    response &&
                    response.data &&
                    response.data.startsWith("https://")
                ) {
                    const url = new URL(response.data)
                    const token = url.searchParams.get("token")
                    const r = url.searchParams.get("r")
                    if (token) {
                        // Redirect to the same route with token as query parameter
                        next({
                            path: `/register/invite`,
                            query: { token, r },
                        })
                    } else {
                        next({ name: "Login" }) // Redirect to login page if token is missing
                    }
                } else {
                    next({ name: "Login" })
                }
            }
        },
    },
    {
        path: "/workspace/switch",
        name: "SwitchWorkspace",
        component: () =>
            import("@/view/pages/general/user-profile/SwitchWorkspace.vue"),
        meta: {
            isAuth: false,
            isBilling: true,
        },
        beforeEnter: [checkIfHNRorTestlify, checkIfSwitchAllowed],
    },
    {
        path: "/workspace/create",
        name: "CreateWorkspaceForm",
        component: () =>
            import("@/view/pages/general/auth-forms/CreateWorkspaceForm.vue"),
        meta: {
            isAuth: true,
        },
        beforeEnter: [checkResellerRoutes, checkWorkspace],
    },
    {
        path: "/workspace/create-new",
        name: "CreateNewWorkspaceForm",
        component: () =>
            import("@/view/pages/general/auth-forms/CreateWorkspaceForm.vue"),
        meta: {
            isAuth: false,
            isBilling: true,
        },
        beforeEnter: [checkResellerRoutes],
    },
    {
        path: "/logging-in",
        name: "IntermediateLogin",
        component: () =>
            import("@/view/pages/general/auth-forms/IntermediateLogin.vue"),
        props: true,
        meta: {
            isAuth: true,
            isBilling: true,
        },
    },
    {
        path: "/email/verify",
        name: "EmailVerification",
        component: () =>
            import(
                "@/view/pages/general/auth-forms/pages/EmailVerification.vue"
            ),
        meta: {
            isAuth: true,
        },
    },
    {
        path: "/mobile/verify",
        name: "MobileVerification",
        component: () =>
            import(
                "@/view/pages/general/auth-forms/pages/PhoneVerification.vue"
            ),
        meta: {
            isAuth: true,
        },
    },
    {
        path: "/phone",
        name: "PhoneNumber",
        component: () =>
            import("@/view/pages/general/auth-forms/PhoneNumber.vue"),
        meta: {
            isAuth: true,
        },
        beforeEnter: (to: any, from: any, next: any) => {
            if (
                ["EmailVerification", "AuthenticatingSignInView"].includes(
                    from.name
                )
            ) {
                return next()
            }
            return next({ name: "Register" })
        },
    },
    {
        path: "/phone/verify",
        name: "PhoneNumberVerification",
        component: () =>
            import(
                "@/view/pages/general/auth-forms/PhoneNumberVerification.vue"
            ),
        meta: {
            isAuth: true,
        },
        beforeEnter: (to: any, from: any, next: any) => {
            if (["PhoneNumber", "InviteeRegister"].includes(from.name)) {
                return next()
            }
            return next({ name: "Register" })
        },
    },
    {
        path: "/talents",
        name: "TalentDetails",
        component: () => import("@/view/pages/general/talents/TalentsMain.vue"),
        meta: {
            isAuth: false,
            activeTab: "Talents",
        },
    },
    {
        path: "/assessments",
        name: "AssessmentMain",
        component: () =>
            import("@/view/pages/general/assessments/AssessmentMain.vue"),
        props: true,
        beforeEnter: (to: any, from: any, next: any) => {
            const getSessionKey = computed(() => store.getters.getSessionKey)
            if (!getSessionCookie() && getSessionKey.value) {
                setSessionCookie(getSessionKey.value)
            }
            store.dispatch(AssessmentActions.RESET_ASSESSMENT)
            return next()
        },
        meta: {
            isAuth: false,
            activeTab: "Assessment",
        },
    },
    {
        path: "/assessment/create/feedback",
        name: "AssessmentFeedback",
        component: () =>
            import("@/view/pages/general/assessments/AssessmentFeedback.vue"),
    },
    {
        path: "/settings/:tab",
        name: "Settings",
        component: () => import("@/view/pages/general/settings/Settings.vue"),
        beforeEnter: [checkResellerRoutes, checkRolesPermission],
        meta: {
            activeTab: "Settings",
        },
    },
    {
        path: "/candidates",
        name: "CandidateMain",
        component: () =>
            import("@/view/pages/general/candidates/CandidateMain.vue"),
        meta: {
            isAuth: false,
            activeTab: "Candidate",
        },
        beforeEnter: [checkResellerRoutes],
    },
    {
        path: "/assessment/compose/:id/:tab?/:childTab?",
        name: "AssessmentCompose",
        component: () =>
            import(
                "@/view/pages/general/create-edit-assessment/Assessment.vue"
            ),
        meta: {
            isAuth: false,
            activeTab: "Assessment",
        },
    },
    {
        path: "/assessment/ai/:id/:tab?",
        name: "AiAssessmentCompose",
        component: () =>
            import("@/view/pages/general/ai-assessment/AIAssessment.vue"),
        meta: {
            isAuth: false,
            activeTab: "Assessment",
        },
        beforeEnter: [checkAssessmentCreationPermission],
    },
    {
        path: "/tests",
        name: "AllTests",
        component: () => import("@/view/pages/general/tests/AllTests.vue"),
        meta: {
            isAuth: false,
            activeTab: "Tests",
        },
    },
    {
        path: "/templates",
        name: "AllTemplates",
        component: () =>
            import("@/view/pages/general/templates/AllTemplates.vue"),
        meta: {
            isAuth: false,
            activeTab: "Templates",
        },
    },
    {
        path: "/questions",
        name: "AllQuestions",
        component: () =>
            import("@/view/pages/general/questions/AllQuestions.vue"),
        meta: {
            isAuth: false,
            activeTab: "Questions",
        },
        beforeEnter: [checkShowQuestions],
    },
    {
        path: "/discover",
        name: "Discover",
        component: () => import("@/view/pages/general/discover/Discover.vue"),
        meta: {
            isAuth: false,
            activeTab: "Discover",
        },
    },
    {
        path: "/try/questions",
        name: "PromoteAllQuestions",
        component: () =>
            import("@/view/pages/general/questions/PromoteAllQuestions.vue"),
        meta: {
            isAuth: false,
            activeTab: "Questions",
        },
    },

    {
        path: "/companytest/:id/:tab?/:childTab?",
        name: "CompanyTest",
        component: () =>
            import("@/view/pages/general/company-tests/CompanyTest.vue"),
        meta: {
            isAuth: false,
            activeTab: "Tests",
        },
    },
    {
        path: "/companytest/ai/:id/:tab?/:childTab?",
        name: "CompanyTestAI",
        component: () =>
            import("@/view/pages/general/company-tests-ai/CompanyTestAI.vue"),
        meta: {
            isAuth: false,
            activeTab: "Tests",
        },
    },
    {
        path: "/assessment/:assessmentId",
        name: "AssessmentDetails",
        component: () =>
            import(
                "@/view/pages/general/assessments/details/AssessmentDetails.vue"
            ),
        meta: {
            isAuth: false,
            activeTab: "Assessment",
        },
    },
    {
        path: "/assessment/:assessmentId/candidate/:candidateId",
        name: "CandidateAssessmentMain",
        component: () =>
            import(
                "@/view/pages/general/candidates/CandidateAssessmentMain.vue"
            ),
        meta: {
            isAuth: false,
            activeTab: isReseller.value ? "Candidate" : "Assessment",
        },
    },
    {
        // do not use same path for any other route
        path: "/assessment/candidate",
        name: "CandidateAssessmentMainPublic",
        component: () =>
            import(
                "@/view/pages/general/candidates/CandidateAssessmentMain.vue"
            ),
        meta: {
            activeTab: isReseller.value ? "Candidate" : "Assessment",
        },
    },
    {
        path: "/candidate/:candidateId",
        name: "CandidateAssessments",
        component: () =>
            import(
                "@/view/pages/general/candidates/CandidateAssessmentMain.vue"
            ),
        meta: {
            isAuth: false,
            activeTab: "Candidate",
        },
    },
    {
        path: "/analytics",
        name: "AnalyticMain",
        component: () =>
            import("@/view/pages/general/analytics/AnalyticMain.vue"),
        meta: {
            isAuth: false,
            activeTab: "Analytics",
        },
    },
    {
        path: "/interviews",
        name: "Interview",
        component: () =>
            import("@/view/pages/general/interview/InterviewQuestions.vue"),
        meta: {
            isAuth: false,
            activeTab: "Interviews",
        },
    },
    {
        path: "/subscription",
        name: "Billing",
        component: () => import("@/view/pages/general/billing/Billing.vue"),
        beforeEnter: [checkResellerRoutes, checkBillingAllowed],
        children: [
            {
                path: "buy/:plan",
                name: "PaymentInformation",
                component: () =>
                    import(
                        "@/view/pages/general/billing/PaymentInformation.vue"
                    ),
                meta: {
                    isAuth: false,
                    isBilling: true,
                    activeTab: "",
                },
            },
            {
                path: "success/:plan",
                name: "PaymentConfirmation",
                component: () =>
                    import(
                        "@/view/pages/general/billing/PaymentConfirmation.vue"
                    ),
                meta: {
                    isAuth: false,
                    isBilling: true,
                    activeTab: "",
                },
            },
            {
                path: "view",
                name: "BillingInformation",
                component: () =>
                    import(
                        "@/view/pages/general/billing/BillingInformation.vue"
                    ),
                meta: {
                    isAuth: false,
                    isBilling: true,
                    activeTab: "",
                },
            },
            {
                path: "billing",
                name: "ManageBilling",
                component: () =>
                    import("@/view/pages/general/billing/ManageBillings.vue"),
                meta: {
                    isAuth: false,
                    isBilling: true,
                    activeTab: "",
                },
            },
            {
                path: "switch/:plan",
                name: "SwitchPlan",
                component: () =>
                    import("@/view/pages/general/billing/SwitchPlan.vue"),
                meta: {
                    isAuth: false,
                    isBilling: true,
                    activeTab: "",
                },
            },
            {
                path: "update/card",
                name: "UpdateCard",
                component: () =>
                    import("@/view/pages/general/billing/UpdateCard.vue"),
                meta: {
                    isAuth: false,
                    isBilling: true,
                    activeTab: "",
                },
            },
            {
                path: "manage/workspace",
                name: "ManageWorkspace",
                component: () =>
                    import("@/view/pages/general/billing/ManageWorkspace.vue"),
                meta: {
                    isAuth: false,
                    isBilling: true,
                    activeTab: "",
                },
            },
            {
                path: "choose",
                name: "ChoosePlan",
                component: () =>
                    import("@/view/pages/general/billing/ChoosePlan.vue"),
                meta: {
                    isAuth: false,
                    isBilling: true,
                    activeTab: "",
                },
            },
            {
                path: "inactive",
                name: "Inactive",
                component: () =>
                    import("@/view/pages/general/billing/Inactive.vue"),
                meta: {
                    isBilling: true,
                },
                beforeEnter: [checkSubscriptionStatus],
            },
            {
                path: "cancel-account",
                name: "CancelAccount",
                component: () =>
                    import("@/view/pages/general/billing/CancelAccount.vue"),
                meta: {
                    isBilling: true,
                },
            },
        ],
    },
    {
        path: "/subscription-expired",
        name: "SubscriptionExpired",
        component: () =>
            import("@/view/pages/general/billing/SubscriptionExpired.vue"),
        meta: {
            isBilling: true,
        },
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
        meta: {},
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/view/pages/general/Error.vue"),
        meta: {},
    },
    // intermediate loading page between Login API and being logged in
    {
        path: "/authenticate",
        name: "AuthenticatingSignInView",
        component: () =>
            import(
                /* webpackChunkName "authenticating-sign-in-view" */ "@/view/pages/general/auth-forms/AuthenticatingSignInView.vue"
            ),
        meta: {
            isAuth: true,
        },
    },
    {
        path: "/play/:sessionId/:id",
        name: "OpenReplaySession",
        component: () => import("@/view/pages/general/OpenReplaySession.vue"),
        meta: {},
    },
    {
        path: "/billing-history",
        name: "BillingPage",
        component: () => import("@/view/pages/general/BillingPage.vue"),
        meta: {},
    },
]

export default routes
