import {
    TypeAssessmentDetails,
    TypeSelectTestCart,
    TypeCustomQuestionCart,
    TypeQualifyingQuestionCart,
    TypeGradingList,
} from "./types"
import { GetterTree } from "vuex"
import { State } from "./state"
import { RootState } from "@/store"

export type Getters = {
    getAssessmentDetails(state: State): TypeAssessmentDetails
    getTestCartItems(state: State): TypeSelectTestCart
    getCustomQuestionCartItems(state: State): TypeCustomQuestionCart
    getWelcomeVideoPath(state: State): String
    getWelcomeExternalVideoLink(state: State): String
    getRedirectedFromLink(state: State): String
    getQualifyingQuestionCartItems(state: State): TypeQualifyingQuestionCart
    getActiveAccordionValue(state: State): String
    getAssessmentConfig(state: State): any
    getAssessmentStatus(state: State): String
    getGradingItems(state: State): TypeGradingList
    getReportSettings(state: State): any
    getQuestionLimit(state: State): Number
    getOptionLimit(state: State): Number
    getCustomizedTemplate(state: State): any
}

export const getters: GetterTree<State, RootState> & Getters = {
    getAssessmentDetails(state) {
        return state.assessmentDetails
    },
    getTestCartItems(state) {
        return state.selectTestCart
    },
    getCustomQuestionCartItems(state) {
        return state.customQuestionCart
    },
    getWelcomeVideoPath(state) {
        return state.welcomeVideoPath
    },
    getWelcomeExternalVideoLink(state) {
        return state.externalWelcomeVideoLink
    },
    getRedirectedFromLink(state) {
        return state.redirectedFrom
    },
    getQualifyingQuestionCartItems(state) {
        return state.qualifyingQuestionCart
    },
    getActiveAccordionValue(state) {
        return state.activeAccordion
    },
    getAssessmentConfig(state) {
        return state.assessmentConfig
    },
    getAssessmentStatus(state) {
        return state.assessmentStatus
    },
    getGradingItems(state) {
        return state.gradingItems
    },
    getReportSettings(state) {
        return state.reportSettings
    },
    getQuestionLimit(state) {
        return state.questionLimit
    },
    getOptionLimit(state) {
        return state.optionLimit
    },
    getCustomizedTemplate(state) {
        return state.customizedEmailTemplates
    },
}
