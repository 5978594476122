import { MutationTree } from "vuex"
import type { State } from "./state"
import {
    TypeAuthUser,
    TypeAccessRegistration,
    TypeAccessLogin,
    TypeDefaultLoginDetails,
    TypeRedirectAfterLogin,
    TypeTeamMember,
} from "./types"

export enum MutationType {
    SET_API_ACCESS_REGISTRATION = "SET_API_ACCESS_REGISTRATION",
    SET_TEAM_MEMBER = "SET_TEAM_MEMBER",
    SET_AUTH_USER = "SET_AUTH_USER",
    SET_API_ACCESS_LOGIN = "SET_API_ACCESS_LOGIN",
    SET_USER_REGISTERED = "SET_USER_REGISTERED",
    REDIRECT_AFTER_LOGIN = "REDIRECT_AFTER_LOGIN",
    SET_CURRENT_USER_ROLE = "SET_CURRENT_USER_ROLE",
    SET_API_DEFAULT_LOGIN_DETAILS = "SET_API_DEFAULT_LOGIN_DETAILS",
    SET_USER_ROLE_ID = "SET_USER_ROLE_ID",
}

export type Mutations = {
    [MutationType.SET_API_ACCESS_REGISTRATION](
        state: State,
        token: TypeAccessRegistration
    ): void
    [MutationType.SET_TEAM_MEMBER](
        state: State,
        teamMemberObject: TypeTeamMember
    ): void
    [MutationType.SET_AUTH_USER](state: State, userDetails: TypeAuthUser): void
    [MutationType.SET_CURRENT_USER_ROLE](state: State, role: string): void
    [MutationType.SET_API_ACCESS_LOGIN](
        state: State,
        loginObject: TypeAccessLogin
    ): void
    [MutationType.SET_API_DEFAULT_LOGIN_DETAILS](
        state: State,
        defaultLoginObject: TypeDefaultLoginDetails
    ): void
    [MutationType.SET_USER_REGISTERED](state: State, val: boolean): void
    [MutationType.REDIRECT_AFTER_LOGIN](
        state: State,
        val: TypeRedirectAfterLogin
    ): void
    [MutationType.SET_USER_ROLE_ID](state: State, roleId: string): void
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationType.SET_API_ACCESS_REGISTRATION](state, regObj) {
        state.accessRegistration = { ...state.accessRegistration, ...regObj }
    },
    [MutationType.SET_USER_REGISTERED](state, val: boolean) {
        state.isUserRegistered = val
    },
    [MutationType.REDIRECT_AFTER_LOGIN](state, val: TypeRedirectAfterLogin) {
        state.redirectAfterLogin = val
    },
    // This mutation will update the user data locally when updated by the user
    [MutationType.SET_AUTH_USER](state, userDetails) {
        state.authUser = {
            ...state.authUser,
            ...userDetails,
        }
    },
    [MutationType.SET_CURRENT_USER_ROLE](state, role) {
        state.authUser.role = role
    },
    [MutationType.SET_TEAM_MEMBER](state, teamMemberObject) {
        state.teamMembers = teamMemberObject
    },
    [MutationType.SET_API_ACCESS_LOGIN](state, loginObject) {
        state.accessLogin = loginObject
    },
    [MutationType.SET_API_DEFAULT_LOGIN_DETAILS](state, defaultLoginObject) {
        state.defaultLoginDetails = defaultLoginObject
    },
    [MutationType.SET_USER_ROLE_ID](state, roleId) {
        state.userRoleId = roleId
    },
}
