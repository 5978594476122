import { v4 as uuidv4 } from "uuid"
import { MutationTree } from "vuex"
import type { State } from "./state"
import { NotificationBanner, TypeEmailFrequency } from "./types"

export enum MutationType {
    SET_NOTIFICATION_BANNER = "SET_NOTIFICATION_BANNER",
    SET_DEFAULT_LANGUAGE = "SET_DEFAULT_LANGUAGE",
    SET_LANGUAGE_LIST = "SET_LANGUAGE_LIST",
    SET_LANGUAGE_JSON = "SET_LANGUAGE_JSON",
    SET_IS_RESELLER = "SET_IS_RESELLER",
    SET_IS_PAID_USER = "SET_IS_PAID_USER",
    SET_IS_DENYCREATEWORKSPACE = "SET_IS_DENYCREATEWORKSPACE",
    SET_IS_PROFILE_DATA_LOADED = "SET_IS_PROFILE_DATA_LOADED",
    SET_IS_CANDIDATE_DELETE = "SET_IS_CANDIDATE_DELETE",
    SET_IS_CANDIDATE_LIST_MODAL_OPEN = "SET_IS_CANDIDATE_LIST_MODAL_OPEN",
    SET_IS_UNREAD_NOTIFICATIONS_COUNT = "SET_IS_UNREAD_NOTIFICATIONS_COUNT",
    SET_IS_DEFAULT_RESELLER = "SET_IS_DEFAULT_RESELLER",
    SET_IS_ADACOMPLIANCE = "SET_IS_ADACOMPLIANCE",
    SET_SESSION_ID = "SET_SESSION_ID",
    SET_DRAWER_VALUE = "SET_DRAWER_VALUE",
    SET_COMPANYTEST_DRAWER_VALUE = "SET_COMPANYTEST_DRAWER_VALUE",
    SET_RESELLER_NAME = "SET_RESELLER_NAME",
    SET_SHOW_PLANS_TAB = "SET_SHOW_PLANS_TAB",
    SET_ADP_VISIBLE_TABLE_HEADINGS = "SET_ADP_VISIBLE_TABLE_HEADINGS",
    SET_ADP_CHECK_LIST_FILTER = "SET_ADP_CHECK_LIST_FILTER",
    SET_ADP_SORT_DETAILS = "SET_ADP_SORT_DETAILS",
    SET_ADP_FILTER_DETAILS = "SET_ADP_FILTER_DETAILS",
    SET_ALP_FILTER_DETAILS = "SET_ALP_FILTER_DETAILS",
    SET_ALP_VISIBLE_TABLE_HEADINGS = "SET_ALP_VISIBLE_TABLE_HEADINGS",
    SET_CLP_FILTER_DETAILS = "SET_CLP_FILTER_DETAILS",
    SET_TLP_FILTER_DETAILS = "SET_TLP_FILTER_DETAILS",
    SET_TALENT_FILTER_DETAILS = "SET_TALENT_FILTER_DETAILS",
    SET_QLP_FILTER_DETAILS = "SET_QLP_FILTER_DETAILS",
    SET_USER_FILTER_DETAILS = "SET_USER_FILTER_DETAILS",
    SET_ASS_TEST_TAB_FILTER_DETAILS = "SET_ASS_TEST_TAB_FILTER_DETAILS",
    SET_ASS_QUE_TAB_FILTER_DETAILS = "SET_ASS_QUE_TAB_FILTER_DETAILS",
    SET_COMPANY_QUE_TAB_FILTER_DETAILS = "SET_COMPANY_QUE_TAB_FILTER_DETAILS",
    SET_RESELLER_DOMAIN = "SET_RESELLER_DOMAIN",
    SET_RESELLER_STATUS = "SET_RESELLER_STATUS",
    SET_LINK_TO_RESELLER_SUBSCRIPTION_STATUS = "SET_LINK_TO_RESELLER_SUBSCRIPTION_STATUS",
    SET_RESELLER_MANAGE_WORKSPACE_STATUS = "SET_RESELLER_MANAGE_WORKSPACE_STATUS",
    SET_RESELLER_VIEW_USAGE_STATUS = "SET_RESELLER_VIEW_USAGE_STATUS",
    SET_CURRENTUSER_DIALCODE = "SET_CURRENTUSER_DIALCODE",
    SET_CURRENTUSER_TIMEZONE = "SET_CURRENTUSER_TIMEZONE",
    SET_IS_TESTLIFY_SUPPORT_LOGIN = "SET_IS_TESTLIFY_SUPPORT_LOGIN",
    SET_DISCOVER_PERCENTAGE = "SET_DISCOVER_PERCENTAGE",
    SET_SAML_SSO_AUTHENTICATION = "SET_SAML_SSO_AUTHENTICATION",
    SET_IS_VALID_CARD = "SET_IS_VALID_CARD",
    SET_EMAIL_REMINDER_FREQUENCY = "SET_EMAIL_REMINDER_FREQUENCY",
    SET_DEFAULT_REGION = "SET_DEFAULT_REGION",
    SHOW_SUBSCRIPTION_INACTIVE_MODAL = "SHOW_SUBSCRIPTION_INACTIVE_MODAL",
}

export type Mutations = {
    [MutationType.SET_NOTIFICATION_BANNER](
        state: State,
        value: NotificationBanner
    ): void
    [MutationType.SET_LANGUAGE_LIST](state: State, value: Array<object>): void
    [MutationType.SET_DEFAULT_LANGUAGE](state: State, value: string): void
    [MutationType.SET_LANGUAGE_JSON](state: State, value: Object): void
    [MutationType.SET_IS_RESELLER](state: State, value: boolean): void
    [MutationType.SET_IS_DEFAULT_RESELLER](state: State, value: boolean): void
    [MutationType.SET_IS_PAID_USER](state: State, value: boolean): void
    [MutationType.SET_RESELLER_STATUS](state: State, value: boolean): void
    [MutationType.SET_LINK_TO_RESELLER_SUBSCRIPTION_STATUS](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_RESELLER_MANAGE_WORKSPACE_STATUS](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_RESELLER_VIEW_USAGE_STATUS](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_CURRENTUSER_DIALCODE](state: State, value: string): void
    [MutationType.SET_CURRENTUSER_TIMEZONE](state: State, value: string): void
    [MutationType.SET_IS_DENYCREATEWORKSPACE](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_DRAWER_VALUE](state: State, value: boolean): void
    [MutationType.SET_COMPANYTEST_DRAWER_VALUE](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_IS_PROFILE_DATA_LOADED](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_IS_CANDIDATE_DELETE](state: State, value: boolean): void
    [MutationType.SET_IS_ADACOMPLIANCE](state: State, value: boolean): void
    [MutationType.SET_IS_CANDIDATE_LIST_MODAL_OPEN](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_IS_UNREAD_NOTIFICATIONS_COUNT](
        state: State,
        value: number
    ): void
    [MutationType.SET_SESSION_ID](state: State): void
    [MutationType.SET_RESELLER_NAME](state: State, value: string): void
    [MutationType.SET_SHOW_PLANS_TAB](state: State, value: object): void
    [MutationType.SET_ADP_VISIBLE_TABLE_HEADINGS](
        state: State,
        value: Array<string>
    ): void
    [MutationType.SET_ADP_CHECK_LIST_FILTER](
        state: State,
        value: Array<string>
    ): void
    [MutationType.SET_ADP_SORT_DETAILS](state: State, value: object): void
    [MutationType.SET_ADP_FILTER_DETAILS](state: State, value: object): void
    [MutationType.SET_ALP_FILTER_DETAILS](state: State, value: object): void
    [MutationType.SET_ALP_VISIBLE_TABLE_HEADINGS](
        state: State,
        value: Array<string>
    ): void
    [MutationType.SET_CLP_FILTER_DETAILS](state: State, value: object): void
    [MutationType.SET_TLP_FILTER_DETAILS](state: State, value: object): void
    [MutationType.SET_TALENT_FILTER_DETAILS](state: State, value: object): void
    [MutationType.SET_QLP_FILTER_DETAILS](state: State, value: object): void
    [MutationType.SET_USER_FILTER_DETAILS](state: State, value: object): void
    [MutationType.SET_ASS_TEST_TAB_FILTER_DETAILS](
        state: State,
        value: object
    ): void
    [MutationType.SET_ASS_QUE_TAB_FILTER_DETAILS](
        state: State,
        value: object
    ): void
    [MutationType.SET_COMPANY_QUE_TAB_FILTER_DETAILS](
        state: State,
        value: object
    ): void
    [MutationType.SET_RESELLER_DOMAIN](state: State, value: string): void
    [MutationType.SET_IS_TESTLIFY_SUPPORT_LOGIN](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_DISCOVER_PERCENTAGE](state: State, value: number): void
    [MutationType.SET_SAML_SSO_AUTHENTICATION](
        state: State,
        value: boolean
    ): void
    [MutationType.SET_EMAIL_REMINDER_FREQUENCY](
        state: State,
        value: object
    ): void
    [MutationType.SET_DEFAULT_REGION](state: State, value: string): void
    [MutationType.SHOW_SUBSCRIPTION_INACTIVE_MODAL](
        state: State,
        value: boolean
    ): void
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationType.SET_NOTIFICATION_BANNER](state, obj: NotificationBanner) {
        state.notificationBanner = { ...obj }
        if (obj.show) {
            // set timeout for new banner
            setTimeout(() => {
                state.notificationBanner = {
                    ...state.notificationBanner,
                    show: false,
                }
            }, 4000)
        }
    },
    [MutationType.SET_DEFAULT_LANGUAGE](state, lang) {
        state.locale.defaultLanguage = lang
    },
    [MutationType.SET_LANGUAGE_LIST](state, list) {
        state.locale.languageList = list
    },
    [MutationType.SET_LANGUAGE_JSON](state, obj) {
        state.locale.languageJson = obj
    },
    [MutationType.SET_RESELLER_STATUS](state, value) {
        state.isResellerActive = value
    },
    [MutationType.SET_LINK_TO_RESELLER_SUBSCRIPTION_STATUS](state, value) {
        state.linkToResellerSubscriptionStatus = value
    },
    [MutationType.SET_RESELLER_MANAGE_WORKSPACE_STATUS](state, value) {
        state.manageWorkspace = value
    },
    [MutationType.SET_RESELLER_VIEW_USAGE_STATUS](state, value) {
        state.resellerViewUsage = value
    },
    [MutationType.SET_CURRENTUSER_DIALCODE](state, value) {
        state.currentUserCountryDialCode = value
    },
    [MutationType.SET_CURRENTUSER_TIMEZONE](state, value) {
        state.currentUserTimeZone = value
    },
    [MutationType.SET_IS_RESELLER](state, value) {
        state.isReseller = value
    },
    [MutationType.SET_IS_DEFAULT_RESELLER](state, value) {
        state.isDefaultReseller = value
    },
    [MutationType.SET_IS_PAID_USER](state, value) {
        state.isPaidUser = value
    },
    [MutationType.SET_IS_DENYCREATEWORKSPACE](state, value) {
        state.isDenyCreateWorkspace = value
    },
    [MutationType.SET_IS_CANDIDATE_DELETE](state, value) {
        state.isCandidateDelete = value
    },
    [MutationType.SET_IS_PROFILE_DATA_LOADED](state, value) {
        state.isProfileDataLoaded = value
    },
    [MutationType.SET_IS_CANDIDATE_LIST_MODAL_OPEN](state, value) {
        state.isCandidateListOpen = value
    },
    [MutationType.SET_IS_UNREAD_NOTIFICATIONS_COUNT](state, value) {
        state.unReadNotificationCount = value
    },
    [MutationType.SET_IS_ADACOMPLIANCE](state, value) {
        if (!value) {
            let element: any = document.querySelector(".asw-container")
            if (element) {
                element.style.display = "none"
            } else {
                console.error('Element with class "asw-container" not found.')
            }
        } else {
            let element: any = document.querySelector(".asw-container")
            if (element) {
                element.style.display = "block"
            } else {
                console.error('Element with class "asw-container" not found.')
            }
        }
        state.ADAcompliance = value
    },
    [MutationType.SET_SESSION_ID](state) {
        state.sessionId = uuidv4()
    },
    [MutationType.SET_DRAWER_VALUE](state, value) {
        state.isDrawerOpen = value
    },
    [MutationType.SET_COMPANYTEST_DRAWER_VALUE](state, value) {
        state.isCompanyTestDrawerOpen = value
    },
    [MutationType.SET_RESELLER_NAME](state, value) {
        state.resellerName = value
    },
    [MutationType.SET_SHOW_PLANS_TAB](state, value) {
        state.showPlansTab = value
    },
    [MutationType.SET_ADP_VISIBLE_TABLE_HEADINGS](state, value) {
        state.ADPSession.visibleTableHeadings = value
    },
    [MutationType.SET_ADP_CHECK_LIST_FILTER](state, value) {
        state.ADPSession.checkListFilter = value
    },
    [MutationType.SET_ADP_SORT_DETAILS](state, value) {
        state.ADPSession.sortDetails = value
    },
    [MutationType.SET_ADP_FILTER_DETAILS](state, value) {
        state.ADPSession.filterDetails = value
    },
    [MutationType.SET_ALP_FILTER_DETAILS](state, value) {
        state.websiteFilters.ALPFilterDetails = value
    },
    [MutationType.SET_ALP_VISIBLE_TABLE_HEADINGS](state, value) {
        state.websiteFilters.ALPVisibleTableHeadings = value
    },
    [MutationType.SET_CLP_FILTER_DETAILS](state, value) {
        state.websiteFilters.CLPFilterDetails = value
    },
    [MutationType.SET_TLP_FILTER_DETAILS](state, value) {
        state.websiteFilters.TLPFilterDetails = value
    },
    [MutationType.SET_TALENT_FILTER_DETAILS](state, value) {
        state.websiteFilters.TalentFilterDetails = value
    },
    [MutationType.SET_QLP_FILTER_DETAILS](state, value) {
        state.websiteFilters.QLPFilterDetails = value
    },
    [MutationType.SET_USER_FILTER_DETAILS](state, value) {
        state.websiteFilters.userFilterDetails = value
    },
    [MutationType.SET_ASS_TEST_TAB_FILTER_DETAILS](state, value) {
        state.websiteFilters.assTestTabFilterDetails = value
    },
    [MutationType.SET_ASS_QUE_TAB_FILTER_DETAILS](state, value) {
        state.websiteFilters.assQueTabFilterDetails = value
    },
    [MutationType.SET_COMPANY_QUE_TAB_FILTER_DETAILS](state, value) {
        state.websiteFilters.companyQueTabFilterDetails = value
    },
    [MutationType.SET_RESELLER_DOMAIN](state, value) {
        state.resellerDomain = value
    },
    [MutationType.SET_IS_TESTLIFY_SUPPORT_LOGIN](state, value) {
        state.isSupportLogin = value
    },
    [MutationType.SET_DISCOVER_PERCENTAGE](state, value) {
        state.discoverPercentage = value
    },
    [MutationType.SET_SAML_SSO_AUTHENTICATION](state, value) {
        state.ssosamlEnabled = value
    },
    [MutationType.SET_IS_VALID_CARD](state, value) {
        state.isValidCard = value
    },
    [MutationType.SET_EMAIL_REMINDER_FREQUENCY](
        state,
        value: TypeEmailFrequency
    ) {
        state.emailReminderFrequency = { ...value }
    },
    [MutationType.SET_DEFAULT_REGION](state, value: string) {
        state.defaultRegion = value
    },
    [MutationType.SHOW_SUBSCRIPTION_INACTIVE_MODAL](state, value: boolean) {
        state.isSubscriptionInactiveModal = value
    },
}
